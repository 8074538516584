<template>
  <div class="card-branco">
    <div class="d-flex justify-content-between align-items-center pb-4">
      <h1>Bairros</h1>
      <router-link
        :to="{ path: '/bairros/novoBairro' }"
        class="btnRoxo"
      >
        Novo bairro
      </router-link>
    </div>
    <strong
      style="color: var(--roxo)"
    >
      Filtrar por:
    </strong>
    <Form
      v-slot="{ errors, isSubmitting }"
      :validation-schema="schema"
      @submit="getBairro"
    >
      <div class="d-flex gap-3 w-100">
        <div class="mb-3 w-100">
          <LabelFromYup 
            name="estado" 
            :schema="schema"
          />
          <Field 
            name="estado" 
            as="select"
            class="inputtext"
            :class="{ 'error': errors.estado }"
            @change="getCidade"
          >
            <option value="">
              Selecionar
            </option>
            <option
              v-for="item in Object.values(estadosDoBrasil)"
              :key="item.nome"
              :value="item.nome"
            >
              {{ item.nome }} - {{ item.sigla }}
            </option>
          </Field>
          <ErrorMessage 
            class="error-msg"
            name="estado"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup 
            name="cidade" 
            :schema="schema"
          />
          <Field 
            name="cidade" 
            as="select"
            class="inputtext"
            :class="{ 'error': errors.cidade }"
          >
            <option value="">
              Selecionar
            </option>
            <option
              v-for="item in listaCidade"
              :key="item.nome"
              :value="item.nome"
            >
              {{ item.nome }} 
            </option>
          </Field>
          <ErrorMessage 
            class="error-msg"
            name="cidade"
          />
        </div>

        <div class="d-flex flex-column align-items-center mt-4">
          <button 
            class="btnAmarelo" 
            :disabled="isSubmitting"
          >
            Pesquisa
          </button>
        </div>
      </div>
    </Form>

    <div class="tabela">
      <table class="w-100">
        <font-awesome-icon
          v-show="isSubmitting" 
          icon="spinner" 
          class="spinner mt-3"
        />
        <col>
        <col>
        <col>
        <col>
        <col>
        <thead>
          <tr>
            <th>Bairro</th>
            <th>Cidade</th>
            <th>Fonte de Informação</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in lista"
            :key="item.id"
          >
            <td>{{ item.nome }}</td>
            <td>{{ item.cidade }}</td>
            <td>{{ item.fonte }}</td>
            <td class="tdBtnAcao">
              <router-link
                :to="{ name: 'BairroEditar', params: { bairroId: item.id } }"
                class="icon"
              >
                <font-awesome-icon
                  icon="pen-to-square"
                />
              </router-link>
            </td>
            <td class="tdBtnAcao">
              <button
                class="icon"
                arial-label="excluir"
                title="excluir"
                @click="excluirBairro(item.id)"
              >
                <font-awesome-icon
                  icon="trash"
                />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import estadosDoBrasil from '@/consts/estadosDoBrasil';
import { bairro as schema } from '@/consts/formSchema';
import { useAlertStore } from '@/store/alert.store';
import { useBairroStore } from '@/store/bairro.store';
import { storeToRefs } from 'pinia';
import { ErrorMessage, Field, Form } from 'vee-validate';

const alertStore = useAlertStore();
const bairroStore = useBairroStore()
const { lista, emFoco, listaCidade } = storeToRefs(bairroStore);

async function excluirBairro(id) {
  alertStore.confirmAction('Deseja mesmo remover esse item?', async () => {
    if (await bairroStore.excluirItem(id)) {
      bairroStore.buscarTudo();
      alertStore.success('Bairro removido.');
    }
  }, 'Remover');
}

async function getCidade(e) {
  try{
    const estado = e.target.value
    await bairroStore.buscarCidade(estado)
  } catch( error){
    alertStore.error(error);
  }
}

async function getBairro(value) {
  try{
    const payload ={
      ...value,
    }
    await bairroStore.buscarTudo(payload)
  } catch( error){
    alertStore.error(error);
  }
}
</script>