<template>
  <div>
    <form
      @submit.prevent="onSubmitInformacaoProprietario"
    >
      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-100">
          <LabelFromYup
            name="vistoria"
            :schema="schema"
            :required="true"
          />
          <Field
            name="vistoria"
            type="date"
            class="inputtext light mb1"
            :class="{ 'error': errors.vistoria }"
            maxlength="10"
          />
          <ErrorMessage
            name="vistoria"
            class="error-msg"
          />
        </div>
        
        <div class="mb-3 w-100">
          <LabelFromYup
            name="entrega_avaliacao"
            :schema="schema"
            :required="true"
          />
          <Field
            name="entrega_avaliacao"
            type="date"
            class="inputtext light mb1"
            :class="{ 'error': errors.entrega_avaliacao }"
            maxlength="10"
          />
          <ErrorMessage
            name="entrega_avaliacao"
            class="error-msg"
          />
        </div> 

        <div class="mb-3 w-100">
          <LabelFromYup 
            name="solicitante"
            :schema="schema"
          />
          <Field
            name="solicitante"
            type="text"
            placeholder="Solicitante"
            class="inputtext"
            :class="{ 'error': errors.solicitante }"
          />
          <ErrorMessage
            class="error-msg"
            name="solicitante"
          />
        </div>
      </div>

      <div>
        <label>Descrição da vistoria</label>
        <QuillEditor
          id="caracteristicas"
          v-model:content="caracteristicas"
          content-type="html"
          theme="snow"
          class="mb-3"
          name="caracteristicas"
        />   
      </div>

      <div>
        <label>Encerramento</label>
        <QuillEditor
          id="caracteristicas"
          v-model:content="caracteristicas"
          content-type="html"
          theme="snow"
          class="mb-3"
          name="caracteristicas"
        />   
      </div>

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-100">
          <LabelFromYup 
            name="finalidade"
            :schema="schema"
          />
          <Field
            name="finalidade"
            type="text"
            placeholder="finalidade"
            class="inputtext"
            :class="{ 'error': errors.finalidade }"
          />
          <ErrorMessage
            class="error-msg"
            name="finalidade"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup 
            name="valor_perito_avaliador" 
            :schema="schema"
          />
          <FloatMaskInput
            name="valor_perito_avaliador" 
            type="text" 
            placeholder="Percepção de Valor do Perito Avaliador"
            :value="values.valor_perito_avaliador"
            class="inputtext"
            converter-para="string"
          />
          <ErrorMessage 
            class="error-msg" 
            name="valor_perito_avaliador"
          />
        </div>

        <div class="d-flex w-100 gap-3 align-items-center">
          <div class="d-flex align-items-center">
            <Field
              name="exibir_curriculo"
              type="checkbox"
              :value="true"
              class="inputcheckbox"
            />
            <LabelFromYup
              name="exibir_curriculo"
              :schema="schema"
              class="ps-2 pb-0"
            />
          </div>
          
          <div class="d-flex align-items-center">
            <Field
              name="exibir_historico"
              type="checkbox"
              :value="true"
              class="inputcheckbox"
            />
            <LabelFromYup
              name="exibir_historico"
              :schema="schema"
              class="ps-2 pb-0"
            />
          </div>
        </div>     
      </div>

      <div class="d-flex flex-column align-items-center mt-4">
        <button 
          class="btnRoxo" 
          :disabled="isSubmitting"
        >
          Salvar
        </button>
        <font-awesome-icon
          v-show="isSubmitting" 
          icon="spinner" 
          class="spinner mt-3"
        />
      </div>
    </form>
  </div>
</template>

<script setup>
import FloatMaskInput from '@/components/FloatMaskInput.vue';
import { avaliacao as schema } from '@/consts/formSchema';
import { useAlertStore } from '@/store/alert.store';
import { useCepStore } from '@/store/buscaCep.store';
import { vMaska } from "maska/vue";
import { storeToRefs } from 'pinia';
import { ErrorMessage, Field, useForm, } from 'vee-validate';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const alertStore = useAlertStore();

const cepGet = useCepStore()
const { lista, chamadasPendentes  } = storeToRefs(cepGet);

const router = useRouter();
const route = useRoute();
const props = defineProps({
  imovelVendidoId: {
    type: Number,
    default: 0,
  },
});

const {
  errors, isSubmitting, values, handleSubmit,
} = useForm({
  // initialValues: emFoco.value,
  validationSchema: schema,
});

async function getCep(cep) {
  let ceptetste = cep.target.value.replace(/\D/g, '')
  try {
    await cepGet.getCep(ceptetste);
  } catch (error) {
    alertStore.error(error);
  }
}
</script>

<style scoped>
.ativo svg {
    color: var(--roxo);
    background-color: initial;
    margin-left: initial;
}
</style>