<template>
  <div class="card-branco">
    <router-link
      to="/opnioesMercado"
      class="btnVoltar"
    >
      <font-awesome-icon
        icon="circle-left"
      />
    </router-link>
    <h1 class="mb-3">Visualizar Amostra</h1>
    <h2>{{selectedItem?.titulo}}</h2>
    <div class="imovel d-flex gap-5 mt-4">
      <ul>
        <li>Tipo<strong>{{selectedItem?.tipo}}</strong></li>
        <li>Preço<strong>{{`R$${dinheiro(selectedItem?.preco)}`}}</strong></li>
        <li>Aréa total<strong>{{selectedItem?.area_total}}</strong></li>
        <li>Aréa útil<strong>{{selectedItem?.area_util}}</strong></li>
        <li>Quartos<strong>{{selectedItem?.quartos}}</strong></li>
        <!-- <li>Banheiro<strong>{{selectedItem?.quartos}}</strong></li> -->
        <li>Suites<strong>{{selectedItem?.suites}}</strong></li>
        <li>Vagas<strong>{{selectedItem?.vagas}}</strong></li>
        <li>IPTU<strong>{{selectedItem?.iptu}}</strong></li>
      </ul>
      <ul>
        <li>Condominio<strong>{{selectedItem?.condominio}}</strong></li>
        <li>Anunciante<strong>{{selectedItem?.anunciante}}</strong></li>
        <li>
          Link do Anuncio
          <strong>
            <a
              :href="selectedItem?.anuncioUrl"
              target="_blank"
              rel="noopener noreferrer"
            >Veja o anuncio completo</a>
          </strong>
        </li>
        <li>Ano de construção<strong></strong></li>
        <li>Data da criação<strong>{{ dataFormatada(selectedItem?.createdAt) }}</strong></li>
        <li>Data da atualização<strong>{{ dataFormatada(selectedItem?.updatedAt) }}</strong></li>
      </ul>
    </div>
    <div>
      <strong>Descrição</strong>
      <p 
        v-html="selectedItem?.descricao"
      />
    </div>
    <div class="mt-4">
      <h1 class="pb-2">Imagens</h1>
      <div class="d-flex gap-3 flex-wrap">
        <div
          v-for="(foto, index) in selectedItem?.fotos"
          :key="index"
          class="imagem-wrapper"
        >
          <img
            :src="foto"
            alt="Imagem do Imóvel"
            class="imagem-imovel"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import dataFormatada from '@/helpers/dataFormatada';
import dinheiro from '@/helpers/dinheiro';
import { useItemStore } from '@/store/itemAmostra.store';
const itemStore = useItemStore();

const selectedItem = itemStore.selectedItem;
</script>

<style scoped>
.ativo svg {
    color: var(--roxo);
    background-color: initial;
    margin-left: initial;
}

.fa-file-lines {
  color: var(--branco);
  font-size: 68px;
}

.imagem-wrapper{
  width: 250px;
  height: 200px;
  overflow: hidden;
  border-radius: 18px;
}

.imagem-imovel {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imovel ul {
    list-style: none;
    padding: 0;
}

.imovel li {
  padding-bottom: 10px;
}

.imovel strong {
  padding-left: 10px;
}
</style>