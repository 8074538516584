<script setup>
import { autenticação as schema } from '@/consts/formSchema';
import { useAuthStore } from '@/store/auth.store';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { useRouter } from 'vue-router';

const router = useRouter();
const authStore = useAuthStore();

async function onSubmit(values) {
  await authStore.login(values);
  if (authStore.token) {
    router.push('/');
  }
}
</script>

<template>
  <div class="avalionLogin">
    <div class="avalionFormLogin">
      <img 
        src="@/assets/imagens/avalionLogo.jpg"
        alt="Logo avalion"
      >
      <Form
        v-slot="{ errors, isSubmitting }"
        :validation-schema="schema"
        @submit="onSubmit"
      >
        <div class="mb-2">
          <LabelFromYup
            name="email"
            :schema="schema"
          />
          <Field 
            name="email"
            placeholder="seu_email@provedor.com"
            type="text"
            class="inputtext"
            :class="{ 'error': errors.email }"
          />
          <ErrorMessage
            class="error-msg"
            name="email"
          />
        </div>

        <div class="mb-2">
          <LabelFromYup 
            name="password"
            :schema="schema"
          />
          <Field
            name="password"
            placeholder="*******"
            type="password"
            class="inputtext"
            :class="{ 'error': errors.password }"  
          />
          <ErrorMessage
            class="error-msg"
            name="password"
          />
        </div>

        <div class="d-flex flex-column align-items-center">
          <button
            class="btnAmarelo"
            :disabled="isSubmitting"
          >
            Entrar
          </button>
          <font-awesome-icon 
            v-show="isSubmitting" 
            icon="spinner" 
            class="spinner mt-3"
          />
          <router-link to="cadastro" class="link mt-3">
            Ainda não tem conta? Cadastre-se.
          </router-link> 
          <router-link to="esqueci-minha-senha" class="link mt-3">
            Esqueci minha senha
          </router-link> 
        </div>
      </Form>
    </div>
  </div>
</template>

<style>
.avalionLogo {
  max-width: 340px;
  display: block;
}

.avalionFormLogin {
  max-width: 398px;
  border: 3px solid var(--roxo);
  padding: 44px;
  border-radius: 39px;
  background-color: var(--branco);
  position: relative;
}

.avalionLogin {
  background-image: url('@/assets/imagens/bgLogin.jpg');
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0 80px 0;
}

.link {
  font-size: 14px;
  color: #5F5F5F;
}
</style>