export default {
  Feminino: {
    nome: 'Feminino',
    valor: 'Feminino',
  },
  Masculino: {
    nome: 'Masculino',
    valor: 'Masculino',
  },
};
