<template>
  <div class="py-3 px-3 bordaAmarela mb-3">
    <p>
      A avaliação de terrenos exige uma análise cautelosa por parte 
      do Corretor/Avaliador relacionadas às características específicas deste tipo de imóvel.
    </p>
  </div>
  <div class="py-3 px-3 bordaAmarela mb-3">
    <p>
      O Avalion faz a sua parte apresentando as amostras disponíveis na região pesquisada,
      similares ao imóvel avaliando, baseadas nas informações fornecidas e nos filtros
      aplicados pelo usuário. Cabe agora o Corretor/Avaliador realizar uma análise detalhada
      do imóvel avaliando e das amostras, aplicando de fato o Método Comparativo Direto de Dados de Mercado.
    </p>
  </div>
  <div class="py-3 px-3 bordaAmarela mb-3">
    <p>
      Fatores como zoneamento, sistema viário, testada, potencial construtivo, viabilidade,
      dentro outros, devem ser analisados e comparados como os imóveis das amostras.
      Muito importante a análise detalhada da Guia Amarela (Parâmetros Urbanísticos
      disponibilizados pela Prefeitura de sua cidade) do imóvel avaliando e das amostras
      disponibilizadas, para daí atribuir o valor de mercado do imóvel avaliando.
    </p>
  </div>
  <div>
    <strong
      class="pb-2 d-block"
      style="color: var(--roxo)"
    >
      Filtrar por:
    </strong>
    <form
      class="mb-3 d-flex gap-3 flex-wrap align-items-end justify-content-between" 
    >
      <div class="d-flex gap-3 align-items-center flex-wrap">
        <div class="w-100">
          <label>Raio da busca</label>
          <Field
            v-model="radius"
            name="radius"
            as="select"
            class="inputtext"
          >
            <option value="">
              Selecionar
            </option>
            <option
              v-for="item in Object.values(raioBusca)"
              :key="item.value"
              :value="item.valor"
            >
              {{ item.nome }}
            </option>
          </Field>
        </div>
        <div class="d-flex gap-3 align-items-center w-100">
          <div class="w-100">
            <label>Área Mínima</label>
            <input
              id="area_min"
              v-model.trim="area_min"
              placeholder="Área Mínima"
              class="inputtext"
              name="area_min"
              type="number"
            >
          </div>
          <div class="w-100">
            <label>Área Máxima</label>
            <input
              id="area_max"
              v-model.trim="area_max"
              placeholder="Área Máxima"
              class="inputtext"
              name="area_max"
              type="number"
            >
          </div>
          <div class="w-100">
            <label>Valor Mínimo</label>
            <input
              id="valor_min"
              v-model.trim="valor_min"
              placeholder="Valor Mínimo"
              class="inputtext"
              name="valor_min"
              type="text"
            >
          </div>
          <div class="w-100">
            <label>Valor Máximo</label>
            <input
              id="valor_max"
              v-model.trim="valor_max"
              placeholder="Valor Máximo"
              class="inputtext"
              name="valor_max"
              type="text"
            >
          </div>
        </div>
        <div class="d-flex gap-3 align-items-center w-100">
          <div class="w-50">
            <label>Dormitórios</label>
            <div class="d-flex gap-3 align-items-center">
              <div>
                <input
                  id="1"
                  v-model="quartos"
                  value="1"
                  class="inputcheckbox"
                  type="checkbox"
                >
                <label class="ps-2">1</label>
              </div>
              <div>
                <input
                  id="2"
                  v-model="quartos"
                  value="2"
                  class="inputcheckbox"
                  type="checkbox"
                >
                <label class="ps-2">2</label>
              </div>
              <div>
                <input
                  id="3"
                  v-model="quartos"
                  value="3"
                  class="inputcheckbox"
                  type="checkbox"
                >
                <label class="ps-2">3</label>
              </div>
              <div>
                <input
                  id="4"
                  v-model="quartos"
                  value="4"
                  class="inputcheckbox"
                  type="checkbox"
                >
                <label class="ps-2">4</label>
              </div>
            </div>
          </div>
          <div class="w-50">
            <label>Vagas de garagem</label>
            <div class="d-flex gap-3 align-items-center">
              <div>
                <input
                  id="1"
                  v-model="garagem"
                  value="1"
                  class="inputcheckbox"
                  type="checkbox"
                >
                <label class="ps-2">1</label>
              </div>
              <div>
                <input
                  id="2"
                  v-model="garagem"
                  value="2"
                  class="inputcheckbox"
                  type="checkbox"
                >
                <label class="ps-2">2</label>
              </div>
              <div>
                <input
                  id="3"
                  v-model="garagem"
                  value="3"
                  class="inputcheckbox"
                  type="checkbox"
                >
                <label class="ps-2">3</label>
              </div>
              <div>
                <input
                  id="4"
                  v-model="garagem"
                  value="4"
                  class="inputcheckbox"
                  type="checkbox"
                >
                <label class="ps-2">4</label>
              </div>
            </div>
          </div>
          <div class="w-100">
            <label>Endereço</label>
            <input
              id="endereco"
              v-model="endereco"
              placeholder="Endereço"
              class="inputtext"
              name="endereco"
              type="text"
            >
          </div>
        </div>
      </div>
    </form>
    <button  
      type="button" 
      class="btnRoxo"
      @click="limparFiltro"
    >
      Limpar filtro
    </button>

    <div class="py-3 px-3 bordaAmarela my-5">
      <p>
        Exibindo <strong>{{ listaFiltrada.length }}</strong> de um total de 
        <strong>{{ lista.length }}</strong> amostras pesquisadas em um raio de 1000 metros
        de distância do imóvel sendo avaliado que se enquadram no tipo Terreno.
        Para maior precisão no trabalho de avaliação, o Corretor Avaliador deverá analisar e
        comparar os dados das amostras pesquisadas com os dados do imóvel avaliando,
        selecionando dentre elas as que mais se assemelham ao imóvel avaliando.
        <br>Selecione no mínimo <strong>4 amostras</strong>.
      </p>
    </div>

    <div class="tabela">
      <table class="w-100">
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <thead>
          <tr>
            <th />
            <th>
              Tipo
            </th>
            <th>
              Título
            </th>
            <th 
              class="ordenagem"
              @click="sortTable('logradouro' || 'bairro')"
            >
              Endereço/Bairro
            </th>
            <th 
              class="ordenagem"
              @click="sortTable('preco')"
            >
              Valor
            </th>
            <th 
              class="ordenagem"
              @click="sortTable('area')"
            >
              Á. Priv.
            </th>
            <th 
              class="ordenagem"
              @click="sortTable('area_total')"
            >
              Á. Total
            </th>
            <th>
              R$/m² total
            </th>
            <th 
              class="ordenagem"
              @click="sortTable('quartos')"
            >
              Quartos
            </th>
            <th 
              class="ordenagem"
              @click="sortTable('vagas')"
            >
              Vagas
            </th>
            <th>
              Idade
            </th>
            <th 
              class="ordenagem"
              @click="sortTable('anunciante')"
            >
              Anunciante
            </th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in listaOrdenada"
            :key="item.id"
          >
            <td>
              <input 
                id="imovel_selecionado"
                type="checkbox"
                name="imovel_selecionado"
                :checked="checkboxStates[item._id] || false"
                @click="selecionaImovel(item._id, $event)"
              >
            </td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.titulo }}</td>
            <td>{{ item.logradouro || item.bairro }}</td>
            <td>R${{ dinheiro(item.preco) }}</td>
            <td>{{ item.area + `m²` }}</td>
            <td>{{ item.area_total + `m²` }}</td>
            <td>R${{ dinheiro(item.preco / item.area_total) }}</td>
            <td>{{ item.quartos }}</td>
            <td>{{ item.vagas }}</td>
            <td>{{ item.idade }}</td>
            <td class="anunciante">{{ item.anunciante }}</td>
            <td class="tdBtnAcao">
              <button @click="selecionaItem(item)" class="icon">
                <font-awesome-icon icon="pen-to-square" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div 
      v-if="amostrasSelecionadas.length"
      class="py-3 px-3 bordaAmarela w-50 my-5 mx-auto"
    >
      <p>
        {{ amostrasSelecionadas.length }} amostras selecionadas
      </p>
    </div>

    <div class="d-flex flex-column align-items-center mt-4">
      <button 
        class="btnAmarelo"  
        @click="renderForm"
        v-show="isSalvarImoveisDisabled"
      >
        Salvar alteração
      </button>
    </div>
  </div>
</template>


<script setup>
import raioBusca from '@/consts/raioBusca';
import dinheiro from '@/helpers/dinheiro';
import { useAlertStore } from '@/store/alert.store';
import { useImoveisStore } from '@/store/buscaImoveisPorLocalizacao';
import { useItemStore } from '@/store/itemAmostra.store';
import { useOpiniaoMercadoStore } from '@/store/opiniaoMercado.store';
import { storeToRefs } from 'pinia';
import { Field } from 'vee-validate';
import { computed, reactive, ref, watch } from 'vue';
import GoogleMap from 'vue-google-maps-ui';
import { useRouter } from 'vue-router';

const alertStore = useAlertStore();
const router = useRouter();

const opiniaoMercadoStore = useOpiniaoMercadoStore()
const imoveisStore = useImoveisStore()
const itemStore = useItemStore();

const { lista } = storeToRefs(imoveisStore);
// eslint-disable-next-line no-undef
const baseUrl = process.env.VUE_APP_API_MAPA;

const checkboxStates = reactive({});

const radius = ref('');
const area_min = ref('');
const area_max = ref('');
const valor_min = ref('');
const valor_max = ref('');
const endereco = ref('');
const quartos = ref([])
const garagem = ref([])
const listaOrdenada = ref([]);
const colunaOrdenada = ref(null);
const direcaoOrdenada = ref(null);

const listaFiltrada = computed(() => {
  return lista.value.filter(item => {
    const areaMatch =
      (!area_min.value || item.area >= area_min.value) &&
      (!area_max.value || item.area <= area_max.value);
    
    const valorMatch =
      (!valor_min.value || item.preco >= parseFloat(valor_min.value.replace(/\D/g, ''))) &&
      (!valor_max.value || item.preco <= parseFloat(valor_max.value.replace(/\D/g, '')));
    
    const quartosMatch =
      quartos.value.length === 0 ||
      quartos.value.includes(item.quartos ? item.quartos.toString() : '');
    
    const garagemMatch =
      garagem.value.length === 0 ||
      garagem.value.includes(item.vagas ? item.vagas.toString() : '');
    
    const enderecoMatch =
      !endereco.value ||
      item.logradouro?.toLowerCase().includes(endereco.value.toLowerCase()) ||
      item.bairro.toLowerCase().includes(endereco.value.toLowerCase());
    return areaMatch && valorMatch && quartosMatch && garagemMatch && enderecoMatch;
  });
});

function limparFiltro() {
  area_min.value = '';
  area_max.value = '';
  valor_min.value = '';
  valor_max.value = '';
  endereco.value = '';
  quartos.value = [];
  garagem.value = [];
}

const amostrasSelecionadas = computed(() => {
  return lista.value.filter(imovel => checkboxStates[imovel._id]);
});

function selecionaImovel(id, event) {
  checkboxStates[id] = event.target.checked;
}

watch(listaFiltrada, () => {
  listaOrdenada.value = listaFiltrada.value;
}, { immediate: true });

function sortTable(column) {
  if (colunaOrdenada.value === column) {
    direcaoOrdenada.value = direcaoOrdenada.value === 'asc' ? 'desc' : 'asc';
  } else {
    colunaOrdenada.value = column;
    direcaoOrdenada.value = 'asc';
  }

  listaOrdenada.value = listaFiltrada.value.slice().sort((a, b) => {
    let valueA = a[column];
    let valueB = b[column];

    if (typeof valueA === 'string') {
      valueA = valueA.toUpperCase();
      valueB = valueB.toUpperCase();
    }

    if (direcaoOrdenada.value === 'asc') {
      return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
    } else {
      return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
    }
  });
}
</script>