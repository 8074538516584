<template>
  <div>
    <form
      @submit.prevent="onSubmitInformacaoProprietario"
    >
      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-2 w-100">
          <label for="imovel_foto">Fotos do imóvel</label>
          <input
            id="imovel_foto"
            name="imovel_foto"
            type="file" 
            class="inputtext mb-2"
            multiple
            @change="onFileChange"
          >
          <small>Arquivos válidos: JPG, PNG e GIF</small>
        </div>

        <div class="mb-2 w-100">
          <label for="imovel_foto">Documentos do imóvel</label>
          <input
            id="imovel_foto"
            name="imovel_foto"
            type="file" 
            class="inputtext mb-2"
            @change="onFileChange"
          >
          <small>Arquivos válidos: PDF.</small>
        </div>  

        <div class="mb-2 w-100">
          <label for="imovel_foto">Declaração COFECI</label>
          <input
            id="imovel_foto"
            name="imovel_foto"
            type="file" 
            class="inputtext mb-2"
            @change="onFileChange"
          >
          <small>Arquivos válidos: PDF.</small>
        </div>
      </div>

      <div class="d-flex flex-column align-items-center mt-4">
        <button 
          class="btnRoxo" 
          :disabled="isSubmitting"
        >
          Salvar
        </button>
        <font-awesome-icon
          v-show="isSubmitting" 
          icon="spinner" 
          class="spinner mt-3"
        />
      </div>
    </form>

    <div 
      v-if="imovel_foto.length" 
      class="d-flex flex-wrap gap-3 mt-5">
      <div 
        v-for="(foto, index) in imovel_foto" 
        :key="index" 
        class="position-relative"
      >
        <img 
          :src="foto" 
          alt="Foto do imóvel" 
          class="img-thumbnail">
        <button 
          type="button" 
          class="btnRoxo position-absolute top-0 end-0 px-3 py-2" 
          @click="removeImage(index)"
        >
          X
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { informacaoProprietario as schema } from '@/consts/formSchema';
import { useAlertStore } from '@/store/alert.store';
import { storeToRefs } from 'pinia';
import { ErrorMessage, Field, useForm, } from 'vee-validate';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const alertStore = useAlertStore();

const router = useRouter();
const route = useRoute();

const imovel_foto = ref([])

const props = defineProps({
  imovelVendidoId: {
    type: Number,
    default: 0,
  },
});

const {
  errors, isSubmitting, values, handleSubmit,
} = useForm({
  // initialValues: emFoco.value,
  validationSchema: schema,
});

async function toBase64(files) {
  return Promise.all([...files].map(file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }));
}

async function onFileChange(e) {
  const files = e.target.files;
  if (files.length) {
    try {
      imovel_foto.value = await toBase64(files);
    } catch (error) {
      alertStore.error("Erro ao converter as imagens para base64");
    }
  }
}

function removeImage(index) {
  imovel_foto.value.splice(index, 1);
}
</script>

<style scoped>
.ativo svg {
    color: var(--roxo);
    background-color: initial;
    margin-left: initial;
}

.img-thumbnail {
    padding: .25rem;
    background-color: var(--bs-body-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: 8px;
    max-width: 356px;
    object-fit: cover;
    height: 300px;
}
</style>