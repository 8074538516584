export default {
  Feminino: {
    Corretora: {
      valor: 'Corretora de imóveis',
    },
    Contadora: {
      valor: 'Contadora',
    },
    Advogada: {
      valor: 'Advogada',
    },
    Engenheira: {
      valor: 'Engenheira',
    },
    OficialJustica: {
      valor: 'Oficial de Justiça',
    },
    Gestora: {
      valor: 'Gestora de negócios imobiliários',
    },
  },
  Masculino: {
    Corretor: {
      valor: 'Corretor de imóveis',
    },
    Contador: {
      valor: 'Contador',
    },
    Advogado: {
      valor: 'Advogado',
    },
    Engenheiro: {
      valor: 'Engenheiro',
    },
    OficialJustica: {
      valor: 'Oficial de Justiça',
    },
    Gestor: {
      valor: 'Gestor de negócios imobiliários',
    },
  }
};