<template>
  <div class="card-branco">
    <div class="d-flex justify-content-between align-items-center pb-4">
      <h1>Meus usuários</h1>
      <router-link
        :to="{ path: '/usuarios/novoUsuario' }"
        class="btnRoxo"
      >
        Novo usuário
      </router-link>
    </div>
    <div class="tabela">
      <table class="w-100">
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <thead>
          <tr>
            <th>Nome</th>
            <th>E-mail</th>
            <th>Último login</th>
            <th>Data de criação</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in lista"
            :key="item.id"
          >
            <td>{{ item.name }}</td>
            <td>{{ item.email }}</td>
            <td>{{ dateToField(item.last_login) }}</td>
            <td>{{ dateToField(item.created_at) }}</td>
            <td class="tdBtnAcao">
              <router-link
                :to="{ 
                  name: 'Usuario Editar',
                  params: { usuarioId: item.id } 
                }"
                class="icon"
              >
                <font-awesome-icon
                  icon="pen-to-square"
                />
              </router-link>
            </td>
            <td class="tdBtnAcao">
              <button
                class="icon"
                arial-label="excluir"
                title="excluir"
                @click="excluirTransferencia(item.id)"
              >
                <font-awesome-icon
                  icon="trash"
                />
              </button>
            </td>
          </tr>
          <tr v-if="chamadasPendentes.lista">
            <td colspan="3">
              <font-awesome-icon
                icon="spinner" 
                class="spinner mt-3"
              />
              Carregando
            </td>
          </tr>
          <tr v-else-if="erro">
            <td colspan="3">
              Erro: {{ erro }}
            </td>
          </tr>
          <tr v-else-if="!lista.length">
            <td colspan="3">
              Nenhum resultado encontrado.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import dateToField from '@/helpers/dateToFiel';
import { useMeusUsuariosStore } from '@/store/meusUsuarios.store';
import { storeToRefs } from 'pinia';

const meusUsuariosStore = useMeusUsuariosStore()
const { lista, chamadasPendentes, erro } = storeToRefs(meusUsuariosStore);

async function iniciar(){
  await meusUsuariosStore.buscarTudo()
}
iniciar()
</script>

<style lang="scss" scoped>

</style>