<template>
  <div>
    <form
      @submit.prevent="onSubmitInformacaoImoveis"
    >
      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-100">
          <LabelFromYup
            name="tipo_imovel"
            :schema="schema"
          />
          <Field
            name="tipo_imovel"
            as="select"
            class="inputtext"
            :class="{ 'error': errors.tipo_imovel }"
          >
            <option value="">
              Selecionar
            </option>
            <option
              v-for="item in Object.values(tipoImovel)"
              :key="item.valor"
              :value="item.valor"
            >
              {{ item.valor }}
            </option>
          </Field>
          <ErrorMessage
            name="tipo_imovel"
            class="error-msg"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup
            name="rural_urbano"
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="rural_urbano"
            name="rural_urbano"
            as="select"
            class="inputtext"
            :class="{ 'error': errors.rural_urbano }"
          >
            <option value="">
              Selecionar
            </option>
            <option
              v-for="item in Object.values(urbanoRural)"
              :key="item.valor"
              :value="item.valor"
            >
              {{ item.nome }}
            </option>
          </Field>
          <ErrorMessage
            name="rural_urbano"
            class="error-msg"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup
            name="tipo_negocio"
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="tipo_negocio"
            name="tipo_negocio"
            as="select"
            class="inputtext"
            :class="{ 'error': errors.tipo_negocio }"
          >
            <option value="">
              Selecionar
            </option>
            <option
              v-for="item in Object.values(tipoNegocio)"
              :key="item.valor"
              :value="item.valor"
            >
              {{ item.valor }}
            </option>
          </Field>
          <ErrorMessage
            name="tipo_negocio"
            class="error-msg"
          />
        </div>
      </div>

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-2 d-flex align-items-center">
          <div>
            <LabelFromYup 
              name="cep"
              :schema="schema"
              :required="true"
            />
            <Field
              v-model="cep"
              v-maska
              name="cep"
              type="text"
              placeholder="CEP"
              class="inputtext"
              maxlength="9"
              data-maska="#####-###"
              :class="{ 'error': errors.cep }"
              @focusout="getCep"
            />
            <ErrorMessage
              class="error-msg"
              name="cep"
            />
          </div>
          <span v-if="chamadasPendentes.emFoco">
            <font-awesome-icon
              icon="spinner" 
              class="spinner mt-3"
            />
          </span>
        </div>

        <div class="mb-2 w-75">
          <LabelFromYup
            name="endereco" 
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="lista.logradouro"
            name="endereco" 
            type="text" 
            placeholder="Rua" 
            class="inputtext"
            :class="{ 'error': errors.endereco }"
          />
          <ErrorMessage 
            class="error-msg"
            name="endereco"
          />
        </div>

        <div class="mb-3 w-25">
          <LabelFromYup 
            name="latitude" 
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="location.latitude"
            name="latitude" 
            type="text" 
            placeholder="latitude" 
            class="inputtext"
            :class="{ 'error': errors.latitude }"
          />
          <ErrorMessage 
            class="error-msg"
            name="latitude"
          />
        </div>

        <div class="mb-3 w-25">
          <LabelFromYup 
            name="longitude" 
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="location.longitude"
            name="longitude" 
            type="text" 
            placeholder="longitude" 
            class="inputtext"
            :class="{ 'error': errors.longitude }"
          />
          <ErrorMessage 
            class="error-msg"
            name="longitude"
          />
        </div>
      </div>

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-75">
          <LabelFromYup 
            name="bairro" 
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="lista.bairro"
            name="bairro" 
            type="text" 
            placeholder="Bairro" 
            class="inputtext"
            :class="{ 'error': errors.bairro }"
          />
          <ErrorMessage 
            class="error-msg"
            name="bairro"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup 
            name="estado" 
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="lista.uf"
            name="estado" 
            type="text" 
            placeholder="Estado" 
            class="inputtext"
            :class="{ 'error': errors.estado }"
          />
          <ErrorMessage 
            class="error-msg"
            name="estado"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup 
            name="cidade"
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="lista.localidade"
            name="cidade" 
            type="text" 
            placeholder="cidade" 
            class="inputtext"
            :class="{ 'error': errors.cidade }"
          />
          <ErrorMessage 
            class="error-msg" 
            name="cidade"
          />
        </div>
      </div>

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-75">
          <LabelFromYup 
            name="matricula" 
            :schema="schema"
          />
          <Field
            name="matricula" 
            type="text" 
            placeholder="Matrícula do Imóvel" 
            class="inputtext"
            :class="{ 'error': errors.matricula }"
          />
          <ErrorMessage 
            class="error-msg"
            name="matricula"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup 
            name="cartorio" 
            :schema="schema"
          />
          <Field
            name="cartorio" 
            type="text" 
            placeholder="Cartório" 
            class="inputtext"
            :class="{ 'error': errors.cartorio }"
          />
          <ErrorMessage 
            class="error-msg"
            name="cartorio"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup
            name="orcamento"
            :schema="schema"
          />
          <Field
            name="orcamento"
            as="select"
            class="inputtext"
            :class="{ 'error': errors.orcamento }"
          >
            <option value="">
              Selecionar
            </option>
            <option
              v-for="item in Object.values(urbanoRural)"
              :key="item.valor"
              :value="item.valor"
            >
              {{ item.nome }}
            </option>
          </Field>
          <ErrorMessage
            name="orcamento"
            class="error-msg"
          />
        </div>
      </div>

      <div class="mb-4 w-100">
        <LabelFromYup 
          name="titulo" 
          :schema="schema"
        />
        <Field
          name="titulo" 
          type="text" 
          placeholder="Título - Ex: Apartamento no Centro de Curitiba" 
          class="inputtext"
          :class="{ 'error': errors.titulo }"
        />
        <ErrorMessage 
          class="error-msg"
          name="titulo"
        />
      </div>

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-100">
          <LabelFromYup 
            name="area_total" 
            :schema="schema"
            :required="true"
          />
          <FloatMaskInput 
            name="area_total" 
            type="text" 
            placeholder="Àrea Total" 
            class="inputtext mb-2"
            :value="values.area_total"
            converter-para="string"
          />
          <ErrorMessage 
            class="error-msg"
            name="area_total"
          />
        </div>
        
        <div class="mb-3 w-100">
          <LabelFromYup 
            name="area_util" 
            :schema="schema"
          />
          <FloatMaskInput 
            name="area_util" 
            type="text"
            :value="values.area_util"
            placeholder="Área Útil" 
            class="inputtext mb-2"
            converter-para="string"
          />
          <ErrorMessage 
            class="error-msg"
            name="area_util"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup
            :schema="schema"
            name="dormitorios"
            class="label"
          />
          <Field
            name="dormitorios"
            type="number"
            min="0"
            class="inputtext"
          />
          <ErrorMessage
            name="dormitorios"
            class="error-msg mb1"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup
            :schema="schema"
            name="suites"
            class="label"
          />
          <Field
            name="suites"
            type="number"
            min="0"
            class="inputtext"
          />
          <ErrorMessage
            name="suites"
            class="error-msg"
          />
        </div>
      </div>

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-100">
          <LabelFromYup
            :schema="schema"
            name="vagas_garagem"
            class="label"
          />
          <Field
            name="vagas_garagem"
            type="number"
            min="0"
            class="inputtext"
          />
          <ErrorMessage
            name="vagas_garagem"
            class="error-msg"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup
            :schema="schema"
            name="numero_banheiros"
            class="label"
          />
          <Field
            name="numero_banheiros"
            type="number"
            min="0"
            class="inputtext"
          />
          <ErrorMessage
            name="numero_banheiros"
            class="error-msg"
          />
        </div>
        
        <div class="mb-3 w-100">
          <LabelFromYup 
            name="valor_condominio" 
            :schema="schema"
          />
          <FloatMaskInput
            name="valor_condominio" 
            type="text" 
            placeholder="Valor do condomínio"
            :value="values.valor_condominio"
            class="inputtext"
            converter-para="string"
          />
          <ErrorMessage 
            class="error-msg" 
            name="valor_condominio"
          />
        </div>
      </div>

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-100">
          <LabelFromYup 
            name="idade_imovel" 
            :schema="schema"
          />
          <Field 
            name="idade_imovel" 
            type="text" 
            placeholder="Idade do Imóvel" 
            class="inputtext"
            :class="{ 'error': errors.idade_imovel }"
          />
          <ErrorMessage 
            class="error-msg"
            name="idade_imovel"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup 
            name="andar_imovel" 
            :schema="schema"
          />
          <Field 
            name="andar_imovel" 
            type="text" 
            placeholder="Andar do Imóvel" 
            class="inputtext"
            :class="{ 'error': errors.andar_imovel }"
          />
          <ErrorMessage 
            class="error-msg"
            name="andar_imovel"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup 
            name="valor_iptu" 
            :schema="schema"
          />
          <FloatMaskInput
            name="valor_iptu" 
            type="text" 
            placeholder="Valor do IPTU Por Ano"
            :value="values.valor_iptu"
            class="inputtext"
            converter-para="string"
          />
          <ErrorMessage 
            class="error-msg" 
            name="valor_iptu"
          />
        </div>
      </div>
      
      <div class="d-flex gap-3 w-100 mb-2">
        <div class="d-flex w-100 align-items-center">
          <Field
            name="area_construida"
            type="checkbox"
            :value="true"
            class="inputcheckbox"
          />
          <LabelFromYup
            name="area_construida"
            :schema="schema"
            class="ps-2 pb-0"
          />
        </div>

        <div class="mb-2 w-100">
          <LabelFromYup
            name="area_construida_m2" 
            :schema="schema"
            :required="true"
          />
          <FloatMaskInput 
            name="area_construida_m2" 
            type="text" 
            placeholder="Área construída (m²)" 
            class="inputtext"
            :value="values.area_construida_m2"
            converter-para="string"
          />
          <ErrorMessage 
            class="error-msg"
            name="area_construida_m2"
          />
        </div>

        <div class="mb-2 w-100">
          <LabelFromYup
            name="valor_area_construida_m2" 
            :schema="schema"
            :required="true"
          />
          <FloatMaskInput 
            name="valor_area_construida_m2" 
            type="text" 
            placeholder="Valor m² área construída" 
            class="inputtext"
            :value="values.valor_area_construida_m2"
            converter-para="string"
          />
          <ErrorMessage 
            class="error-msg"
            name="valor_area_construida_m2"
          />
        </div>
      </div>

      <div>
        <label>Características imóvel</label>
        <QuillEditor
          id="caracteristicas"
          v-model:content="caracteristicas"
          content-type="html"
          theme="snow"
          class="mb-3"
          name="caracteristicas"
        />   
      </div>

      <div class="d-flex flex-column align-items-center mt-4">
        <button 
          class="btnRoxo" 
          :disabled="isSubmitting"
        >
          Salvar
        </button>
        <font-awesome-icon
          v-show="isSubmitting" 
          icon="spinner" 
          class="spinner mt-3"
        />
      </div>
    </form>
  </div>
</template>

<script setup>
import FloatMaskInput from '@/components/FloatMaskInput.vue';
import { informacaoImovel as schema } from '@/consts/formSchema';
import tipoImovel from '@/consts/tipoImovel';
import tipoNegocio from '@/consts/tipoNegocio';
import urbanoRural from '@/consts/urbanoRural';
import { useAlertStore } from '@/store/alert.store';
import { useCepStore } from '@/store/buscaCep.store';
import { vMaska } from "maska/vue";
import { storeToRefs } from 'pinia';
import { ErrorMessage, Field, useForm, } from 'vee-validate';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const alertStore = useAlertStore();

const cepGet = useCepStore()
const { lista, chamadasPendentes  } = storeToRefs(cepGet);

const router = useRouter();
const route = useRoute();
const props = defineProps({
  imovelVendidoId: {
    type: Number,
    default: 0,
  },
});

const caracteristicas = ref('');
const location = ref({
  latitude: '',
  longitude: ''
})

const {
  errors, isSubmitting, values, handleSubmit,
} = useForm({
  // initialValues: emFoco.value,
  validationSchema: schema,
});

function onDescriptionInput(content) {
  caracteristicas.value = content;
}


async function getCep(cep) {
  let ceptetste = cep.target.value.replace(/\D/g, '')
  try {
    await cepGet.getCep(ceptetste);
    location.value.latitude = cepGet.location.latitude;
    location.value.longitude = cepGet.location.longitude;
  } catch (error) {
    alertStore.error(error);
  }
}
</script>

<style scoped>
.ativo svg {
    color: var(--roxo);
    background-color: initial;
    margin-left: initial;
}
</style>