<template>
  <section class="perfil">
    <section class="card-branco">
      <router-link
        to="/usuarios"
        class="btnVoltar"
      >
        <font-awesome-icon
          icon="circle-left"
        />
      </router-link>
      <section>
        <div class="py-3 px-3 bordaAmarela mb-3">
          <p>
            Olá Usuário! É muito importante a inserção dos seus dados,
            pois eles farão parte de todo o trabalho realizado na ferramenta e também
            para contato da nossa equipe com você. Tenha uma ótima experiência!
          </p>
        </div>
      </section>
      <form
        @submit.prevent="onSubmitAtualizarPerfil"
      >
        <div class="mb-3">
          <LabelFromYup 
            name="name" 
            :schema="schema"
            :required="true"
          />
          <Field
            name="name"
            placeholder="Seu nome"
            type="text"
            class="inputtext"
            :class="{ 'error': errors.name }"
          />
          <ErrorMessage
            class="error-msg"
            name="name"
          />
        </div>

        <div class="form-group w-100 my-2">
          <label>
            <Field
              name="tipo_pessoa"
              type="radio"
              value="Pessoa Física"
              class="inputcheckbox"
            />
            <span class="ps-2">Pessoa Física (Corretor)</span>
          </label>
          <label>
            <Field
              name="tipo_pessoa"
              type="radio"
              value="Pessoa Jurídica"
              class="inputcheckbox"
            />
            <span class="ps-2">Pessoa Jurídica(Imobiliária)</span>
          </label>
        </div>

        <div class="form-group w-100">
          <div class="mb-3">
            <LabelFromYup 
              name="cpf" 
              :schema="schema"
              :required="true"
            />
            <Field 
              v-maska
              name="cpf"
              type="text"
              placeholder="CPF/CNPJ"
              class="inputtext"
              maxlength="15"
              data-maska="['###.###.###-##', '##.###.###/####-##']"
              :class="{ 'error': errors.cpf }"
            />
            <ErrorMessage
              class="error-msg"
              name="cpf"
            />
          </div>

          <div class="mb-3">
            <LabelFromYup 
              name="rg" 
              :schema="schema"
              :required="true"
            />
            <Field 
              v-maska
              name="rg"
              type="text"
              placeholder="RG"
              class="inputtext mb-2"
              maxlength="15"
              :class="{ 'error': errors.rg }"
            />
            <ErrorMessage
              class="error-msg"
              name="rg"
            />
          </div>
        </div>

        <div class="form-group w-100">
          <div class="form-group w-100">
            <div class="mb-3">
              <LabelFromYup
                name="nascimento"
                :schema="schema"
                :required="true"
              />
              <Field
                name="nascimento"
                type="date"
                class="inputtext light mb1"
                :class="{ 'error': errors.nascimento }"
                maxlength="10"
              />
              <ErrorMessage
                name="nascimento"
                class="error-msg"
              />
            </div>

            
            <div class="mb-3">
              <LabelFromYup
                name="sexo"
                :schema="schema"
                :required="true"
              />
              <Field
                name="sexo"
                as="select"
                class="inputtext light mb1"
                :class="{ 'error': errors.sexo }"
                @change="setFieldValue('profissao', '')"
              >
                <option value="">
                  Selecionar
                </option>
                <option
                  v-for="item in Object.values(genero)"
                  :key="item.valor"
                  :value="item.valor"
                >
                  {{ item.valor }}
                </option>
              </Field>
              <ErrorMessage
                name="sexo"
                class="error-msg"
              />
            </div>
          </div>

          <div class="w-100">
            <div class="mb-3">
              <LabelFromYup
                name="profissao"
                :schema="schema"
                :required="true"
              />
              <Field
                name="profissao"
                as="select"
                class="inputtext"
                :class="{ 'error': errors.profissao }"
              >
                <option value="">
                  Selecionar
                </option>
                <option 
                  v-for="item in profissoes[emFoco?.sexo]" 
                  :key="item.valor"
                  :value="item.valor"
                >
                  {{ item.valor }}
                </option>
              </Field>
              <ErrorMessage
                name="profissao"
                class="error-msg"
              />
            </div>
          </div>
        </div>

        <div class="form-group w-100">
          <div class="mb-3">
            <LabelFromYup 
              name="email" 
              :schema="schema"
              :required="true"
            />
            <Field
              name="email"
              placeholder="seu_email@provedor.com" 
              type="text" 
              class="inputtext"
              :class="{ 'error': errors.email }"
            />
            <ErrorMessage 
              class="error-msg" 
              name="email"
            />
          </div>

          <div class="mb-3">
            <LabelFromYup 
              name="telefone_celular" 
              :schema="schema"
              :required="true"
            />
            <Field 
              v-maska
              name="telefone_celular"
              type="text"
              placeholder="(00) 00000-0000"
              class="inputtext"
              maxlength="15"
              data-maska="(##) #####-####"
              :class="{ 'error': errors.telefone_celular }"
            />
            <ErrorMessage
              class="error-msg"
              name="telefone_celular"
            />
          </div>
        </div>

        <div class="form-group w-100">
          <div class="mb-3">
            <LabelFromYup
              name="sexo"
              :schema="schema"
              :required="true"
            />
            <Field
              name="creci"
              as="select"
              class="inputtext"
              :class="{ 'error': errors.creci }"
            >
              <option value="">
                Selecionar
              </option>
              <option
                v-for="item in Object.values(creciRegiao)"
                :key="item.valor"
                :value="item.valor"
              >
                {{ item.valor }}
              </option>
            </Field>
            <ErrorMessage
              name="creci"
              class="error-msg"
            />
          </div>

          <div class="mb-3">
            <LabelFromYup 
              name="numero_creci" 
              :schema="schema"
            />
            <Field 
              name="numero_creci"
              type="text"
              placeholder="Número creci"
              class="inputtext"
            />
          </div>
        </div>

        <div class="form-group w-100">
          <div class="mb-3">
            <LabelFromYup 
              name="cnai" 
              :schema="schema"
            />
            <Field
              name="cnai"
              placeholder="CNAI" 
              type="text" 
              class="inputtext"
            />
          </div>

          <div class="form-group w-100">
            <div class="mb-3">
              <LabelFromYup 
                name="cor_perfil" 
                :schema="schema"
              />
              <Field 
                v-maska
                name="cor_perfil"
                type="color"
                class="discoCores"
              />
            </div>

            <div class="mb-3">
              <LabelFromYup 
                name="cor_text_perfil" 
                :schema="schema"
              />
              <Field 
                v-maska
                name="cor_text_perfil"
                type="color"
                class="discoCores"
              />
            </div>
          </div> 
        </div>

        <div class="d-flex w-100 gap-3">
          <div class="mb-3 d-flex align-items-end">
            <div>
              <LabelFromYup 
                name="cep"
                :schema="schema"
                :required="true"
              />
              <Field
                v-model="cep"
                v-maska
                name="cep"
                type="text"
                placeholder="CEP"
                class="inputtext"
                maxlength="9"
                data-maska="#####-###"
                :class="{ 'error': errors.cep }"
                @focusout="getCep"
              />
              <ErrorMessage
                class="error-msg"
                name="cep"
              />
            </div>
            <span v-if="chamadasPendentes.emFoco">
              <font-awesome-icon
                icon="spinner" 
                class="spinner mt-3"
              />
            </span>
          </div>

          <div class="mb-2 w-75">
            <LabelFromYup
              name="endereco" 
              :schema="schema"
              :required="true"
            />
            <Field
              v-model="lista.logradouro"
              name="endereco" 
              type="text" 
              placeholder="Rua" 
              class="inputtext light mb-2"
              :class="{ 'error': errors.endereco }"
            />
            <ErrorMessage 
              class="error-msg"
              name="endereco"
            />
          </div>
        </div>

        <div class="d-flex w-100 gap-3">
          <div class="mb-3 w-25">
            <LabelFromYup 
              name="numero" 
              :schema="schema"
              :required="true"
            />
            <Field 
              name="numero" 
              type="text" 
              placeholder="Número" 
              class="inputtext"
              :class="{ 'error': errors.numero }"
            />
            <ErrorMessage 
              class="error-msg" 
              name="numero"
            />
          </div>

          <div class="mb-3 w-75">
            <LabelFromYup 
              name="bairro" 
              :schema="schema"
              :required="true"
            />
            <Field
              v-model="lista.bairro"
              name="bairro" 
              type="text" 
              placeholder="Bairro" 
              class="inputtext"
              :class="{ 'error': errors.bairro }"
            />
            <ErrorMessage 
              class="error-msg"
              name="bairro"
            />
          </div>
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup 
            name="complemento" 
            :schema="schema"
          />
          <Field 
            name="complemento"
            type="text" 
            placeholder="Complemento" 
            class="inputtext mb-2"
          />
        </div>

        <div class="form-group w-100">
          <div class="mb-3 w-100">
            <LabelFromYup 
              name="cidade" 
              :schema="schema"
            />
            <Field
              v-model="lista.localidade"
              name="cidade" 
              type="text" 
              placeholder="cidade" 
              class="inputtext"
              :class="{ 'error': errors.cidade }"
            />
            <ErrorMessage 
              class="error-msg" 
              name="cidade"
            />
          </div>

          <div class="mb-3 w-100">
            <LabelFromYup 
              name="estado" 
              :schema="schema"
            />
            <Field 
              v-model="lista.uf"
              name="estado" 
              type="text" 
              placeholder="Estado" 
              class="inputtext light mb-2"
              :class="{ 'error': errors.estado }"
            />
            <ErrorMessage 
              class="error-msg"
              name="estado"
            />
          </div>
        </div>

        <div class="mb-2 w-100">
          <label 
            for="tabela_honorarios"
            name="tabela_honorarios"
          >
            Tabela de Honorários
          </label>
          <input
            id="tabela_honorarios"
            name="tabela_honorarios"
            type="file" 
            class="inputtext"
            @change="onFileChange"
          >
        </div>

        <div>
          <div>
            <label style="color: var(--roxo)">
              <strong>Currículo</strong>
            </label>
            <QuillEditor 
              
              content-type="html"
              theme="snow"
              class="mb-3"
            />   
          </div>

          <div>
            <label style="color: var(--roxo)">
              <strong>Histórico imobiliário</strong>
            </label>
            <QuillEditor 
              
              content-type="html"
              theme="snow"
              class="mb-3"
            />     
          </div>
        </div>


        <div class="d-flex flex-column align-items-center mt-4">
          <button 
            class="btnRoxo" 
            :disabled="isSubmitting"
          >
            Salvar
          </button>
          <font-awesome-icon 
            v-show="isSubmitting" 
            icon="spinner" 
            class="spinner mt-3"
          />
        </div>
      </Form>
    </section>

    <section class="card-branco">
      <div>
        <h2 class="pb-3">Foto de perfil</h2>
        <div v-if="emFoco?.imagem_perfil"  class="user d-flex align-items-center">
          <img :src="emFoco?.imagem_perfil">
        </div>
        <div v-else class="user d-flex align-items-center">
          <font-awesome-icon 
            icon="user"
          />
        </div>
        <div class="d-flex align-items-center gap-1 icon-content">
          <button class="icon">
            <font-awesome-icon
              icon="trash"
            />
          </button>
          <Form
            @submit="atualionPhotoChangezarFotoPerfil"
          >
            <div>
              <label 
                class="labelFile icon"
                for="imovel_foto"
              >
                <font-awesome-icon
                  icon="pen-to-square"
                />
              </label>
              <Field
                name="imovel_foto" 
                type="file"
                class="d-none"
                id="imovel_foto"
                @change="onPhotoChange"
              />
            </div>
          </Form>
        </div>
      </div>

      <div class="py-3">
        <h2 class="pb-3">Logo para PTAMs e Análises</h2>
        <!-- <pre>{{user}}</pre> -->
        <div 
          v-if="!user?.logo_avaliacao"
          class="logo-assinatura  pb-3 d-flex align-items-center"
        >
          <img :src="emFoco?.logo_avaliacao">
        </div>
        <div 
          v-else 
          class="image d-flex align-items-center"
        >
          <font-awesome-icon 
            icon="image"
          />
        </div>
        <div class="d-flex align-items-center gap-1 icon-content">
          <button class="icon">
            <font-awesome-icon
              icon="trash"
            />
          </button>
          <Form
            @submit="aaa"
          >
            <div>
              <label 
                class="labelFile icon"
                for="fotoPerfil"
              >
                <font-awesome-icon
                  icon="pen-to-square"
                />
              </label>
              <Field
                name="old_password" 
                type="file"
                class="d-none"
                id="fotoPerfil"
              />
            </div>
          </Form>
        </div>
      </div>

      <div class="py-3">
        <h2 class="pb-3">Assinatura</h2>
        <div 
          v-if="!user?.imagem_assinatura" 
          class="image d-flex align-items-center"
        >
          <font-awesome-icon 
            icon="image"
          />
        </div>
        <div 
          v-else 
          class="pb-3 d-flex align-items-center logo-assinatura "
        >
          <img :src="emFoco.imagem_assinatura">
        </div>
        <div class="d-flex align-items-center gap-1 icon-content">
          <button class="icon">
            <font-awesome-icon
              icon="trash"
            />
          </button>
          <Form
            @submit="aaa"
          >
            <div>
              <label 
                class="labelFile icon"
                for="fotoPerfil"
              >
                <font-awesome-icon
                  icon="pen-to-square"
                />
              </label>
              <Field
                name="old_password" 
                type="file"
                class="d-none"
                id="fotoPerfil"
              />
            </div>
          </Form>
        </div>
      </div>

      <div>
        <h2 class="pb-3">Senha</h2>
        <form
          @submit.prevent="onSubmitNovaSenhaPerfil"
        >
          <div class="mb-3">
            <LabelFromYup 
              name="old_password" 
              :schema="novaSenhaPerfilSchema"
            />
            <Field
              name="old_password" 
              placeholder="Senha atual" 
              type="password" 
              class="inputtext"
              :class="{ 'error': errors.old_password }"  
            />
            <ErrorMessage 
              class="error-msg" 
              name="old_password"
            />
          </div>

          <div class="mb-3">
            <LabelFromYup 
              name="password" 
              :schema="novaSenhaPerfilSchema"
            />
            <Field
              name="password" 
              placeholder="Nova senha" 
              type="password" 
              class="inputtext"
              :class="{ 'error': errors.password }"  
            />
            <ErrorMessage 
              class="error-msg" 
              name="password"
            />
          </div>

          <div class="mb-3">
            <LabelFromYup 
              name="password_confirmation" 
              :schema="novaSenhaPerfilSchema"
            />
            <Field
              name="password_confirmation" 
              placeholder="Confirmar nova senha" 
              type="password" 
              class="inputtext"
              :class="{ 'error': errors.password_confirmation }"  
            />
            <ErrorMessage 
              class="error-msg" 
              name="password_confirmation"
            />
          </div>

          <div class="d-flex flex-column align-items-center">
            <button 
              class="btnRoxo" 
              :disabled="isSubmitting"
            >
              ALTERAR SENHA
            </button>
            <font-awesome-icon 
              v-show="isSubmitting" 
              icon="spinner" 
              class="spinner mt-3"
            />
          </div>
        </form>
      </div>
    </section>
  </section>
</template>

<script setup>
import creciRegiao from '@/consts/creciRegiao';
import {
  novaSenhaPerfil as novaSenhaPerfilSchema,
  meuPerfil as schema
} from '@/consts/formSchema';
import genero from '@/consts/genero';
import profissoes from '@/consts/profissao';
import { useAlertStore } from '@/store/alert.store';
import { useAuthStore } from '@/store/auth.store';
import { useCepStore } from '@/store/buscaCep.store';
import { useMeusUsuariosStore } from '@/store/meusUsuarios.store';
import { vMaska } from "maska/vue";
import { storeToRefs } from 'pinia';
import { ErrorMessage, Field, useForm } from 'vee-validate';
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const alertStore = useAlertStore();
const authStore = useAuthStore();
const cepGet = useCepStore()

const meusUsuariosStore = useMeusUsuariosStore()

const { lista, chamadasPendentes  } = storeToRefs(cepGet);
const { emFoco } = storeToRefs(meusUsuariosStore);

const fileXml = ref(null)
const imagem_perfil = ref('')
const props = defineProps({
  usuarioId: {
    type: Number,
    default: 0,
  },
});

const {
  errors, isSubmitting, setFieldValue, resetForm, values, handleSubmit,
} = useForm({
  initialValues: emFoco.value,
  validationSchema: schema,
});

async function onSubmitNovaSenhaPerfil(values) {
  await authStore.novaSenhaPerfil(values);
  if(authStore.token){
    location.reload();  
  }
}

async function getCep(cep) {
  let ceptetste = cep.target.value.replace(/\D/g, '')
  try {
    await cepGet.getCep(ceptetste);
  } catch (error) {
    alertStore.error(error);
  }
}

const onFileChange = (event) => {
  fileXml.value = event.target.files[0];
  console.log(fileXml.value)
};

async function onSubmitAtualizarPerfil(values) {
  try{
    await authStore.atualizarPerfil(values);
  } catch (erros){
    alertStore.error(erros.message); 
  }
}

function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
    console.log(file)
  });
}

async function onPhotoChange(e) {
  const file = e.target.files[0];
  if (file) {
    try {
      imagem_perfil.value = await toBase64(file);
      atualizarFotoPerfil()
    } catch (error) {
      alertStore.error("Erro ao converter a imagem para base64");
    }
  }
}

async function atualizarFotoPerfil() {
  const payload = {
    ...emFoco.value,
    imagem_perfil: imagem_perfil.value || emFoco.value.imovel_foto,
  }
  try{
    await authStore.atualizarPerfil(payload);
  } catch (erros){
    alertStore.error(erros.message); 
  }
}

async function iniciar (){
  if(props.usuarioId){
    await meusUsuariosStore.buscarItem(props.usuarioId)
  }
}
iniciar()

watch(emFoco, (novoValor) => {
  resetForm({ values: novoValor });
});
</script>

<style scoped>
h2{
  color: var(--roxo);
  font-size: 20px;
  font-weight: 600;
}

.perfil{
  display: grid;
  grid-template-columns: 64% 34%;
  gap: 20px;
}
.discoCores {
  width: 52px;
  height: 52px;
  border: none;
  display: block;
}

.fa-user, .fa-image, .fa-file-lines {
  color: var(--branco);
  font-size: 68px;
}

.user {
  background: var(--cinza);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
}

.user img{
  border-radius: 50%;
  height: 100px;
  object-fit: cover;
}

.image {
  background: var(--cinza);
  height: 125px;
  border-radius: 12px;
}

.logo-assinatura{
  max-width: 200px;
  margin: auto;
}

.icon-content{
  border: 1px solid var(--roxo);
  border-radius: 7px;
  width: max-content;
  margin: auto;
  position: relative;
  top: -15px;
  background: var(--branco);
  padding: 2px 15px;
}

.labelFile{
  border: none;
  background: none;
  color: var(--cinza);
  font-size: 20px;
  padding-bottom: 0;
  cursor: pointer;
}

.ativo svg {
  background: initial; 
  color: var(--roxo); 
}
</style>