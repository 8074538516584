<template>
  <div class="card-branco">
    <div class="d-flex justify-content-between align-items-center pb-4">
      <h1>PTAMs</h1>
      <div class="d-flex gap-3">
        <router-link
          :to="{ name: 'Novo Ptams' }"
          class="btnRoxo"
        >
          Novo PTAMs
        </router-link>
        <router-link
          :to="{ name: 'Ptams rascunho' }"
          class="btnBordaRoxo"
        >
          Rascunhos
        </router-link>
      </div>
    </div>
    <strong
      style="color: var(--roxo)"
    >
      Filtrar por:
    </strong>
    <form
      class="mb-3 d-flex gap-3 flex-wrap align-items-end justify-content-between" 
      @submit.prevent="atualizarUrl"
    >
      <div class="d-flex gap-3 align-items-center">
        <div class="inputtextMenor">
          <label>Id</label>
          <input
            id="id"
            v-model.trim="idBusca"
            placeholder="ID"
            class="inputtext "
            name="id"
            type="text"
          >
        </div>

        <div class="mw-100">
          <label>Endereço</label>
          <input
            id="endereco"
            v-model.trim="endereco"
            placeholder="Endereço"
            class="inputtext"
            name="endereco"
            type="text"
          >
        </div>

        <div>
          <label>Bairro</label>
          <input
            id="bairro"
            v-model.trim="bairro"
            placeholder="Bairro"
            class="inputtext"
            name="bairro"
            type="text"
          >
        </div>

        <div>
          <label>Tipo de negócio</label>
          <select
            id="tipo_negocio"
            v-model.trim="tipo_negocio"
            class="inputtext"
            name="tipo_negocio"
          >
            <option 
              value=""
            />
            <option
              v-for="item in Object.values(tipoNegocio)"
              :key="item.valor"
              :value="item.valor"
            >
              {{ item.nome }}
            </option>
          </select>
        </div>

        <div>
          <label>Tipo de Imóvel</label>
          <select
            id="tipo_imovel"
            v-model.trim="tipo_imovel"
            class="inputtext"
            name="tipo_imovel"
          >
            <option 
              value=""
            />
            <option
              v-for="item in Object.values(tipoImovel)"
              :key="item.valor"
              :value="item.valor"
            >
              {{ item.valor }}
            </option>
          </select>
        </div>
      </div>
      <div class="d-flex gap-3">
        <button @click="limparFiltro" type="button" class="btnLabel">
          <font-awesome-icon
            icon="trash"
          />
          Limpar
        </button>
        <button class="btnAmarelo">
          Pesquisar
        </button>
      </div>
    </form>
    <div class="tabela">
      <table class="w-100">
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <thead>
          <tr>
            <th>ID</th>
            <th>Endereço</th>
            <th>Tipo de imóvel</th>
            <th>Negócio</th>
            <th>Data</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="tdBtnAcao">
              <router-link
                to="/login"
                class="icon"
              >
                <font-awesome-icon
                  icon="pen-to-square"
                />
              </router-link>
            </td>
            <td class="tdBtnAcao">
              <button
                class="icon"
                arial-label="excluir"
                title="excluir"
                @click="excluirTransferencia(item.id)"
              >
                <font-awesome-icon
                  icon="trash"
                />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import tipoImovel from '@/consts/tipoImovel';
import tipoNegocio from '@/consts/tipoNegocio';
import { useOpiniaoMercadoStore } from '@/store/opiniaoMercado.store';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const opiniaoMercadoStore = useOpiniaoMercadoStore()
const { lista, linhas, chamadasPendentes, erro} = storeToRefs(opiniaoMercadoStore);

const route = useRoute();
const router = useRouter();

const idBusca = ref(route.query.id)
const endereco = ref(route.query.endereco)
const bairro = ref(route.query.bairro)
const tipo_negocio = ref(route.query.tipo_negocio
? Object.keys(tipoNegocio)
  .find((x) => x.toLowerCase() === route.query.tipo_negocio.toLocaleLowerCase())
: undefined);

const tipo_imovel = ref(route.query.tipo_imovel
? Object.keys(tipoImovel)
  .find((x) => x.toLowerCase() === route.query.tipo_imovel.toLocaleLowerCase())
: undefined);


const consulta = {};
function atualizarUrl() {

  if (idBusca.value) {
    consulta.id = idBusca.value;
  }

  if (endereco.value) {
    consulta.endereco = endereco.value;
  }

  if (bairro.value) {
    consulta.neighborhood = bairro.value;
  }

  if (tipo_negocio.value) {
    consulta.business = tipo_negocio.value;
  }

  if (tipo_imovel.value) {
    consulta.type = tipo_imovel.value;
  }

  router.push({
    query: consulta,
  });
}

watch([
  () => route.query.id,
  () => route.query.endereco,
  () => route.query.neighborhood,
  () => route.query.type,
  () => route.query.business,
], () => {
  opiniaoMercadoStore.$reset();
  opiniaoMercadoStore.buscarTudo(consulta);
}, { immediate: true });

function limparFiltro(){
  idBusca.value = '';
  endereco.value = '';
  bairro.value = '';
  tipo_negocio.value = '';
  tipo_imovel.value = '';

  router.push({
    query: {}
  });

  opiniaoMercadoStore.$reset();
  opiniaoMercadoStore.buscarTudo({});
}

</script>

<style lang="scss" scoped>

</style>