import { defineStore } from 'pinia';
import { useAuthStore } from '@/store/auth.store';
import { useAlertStore } from '@/store/alert.store';

// eslint-disable-next-line no-undef
const baseUrl = process.env.VUE_APP_API_URL;

export const useCapitacaoDadosStore = defineStore('CapitacaoDados', {
  state: () => ({
    lista: [],
    emFoco: null,
    filePath: "",
    chamadasPendentes: {
      lista: false,
      emFoco: false,
      filePath: false,
    },
    erro: null,
  }),
  actions: {
    async buscarTudo(params = {}) {
      this.chamadasPendentes.lista = true;
      this.erro = null;

      try {
        const request  = await this.requestS.get(`${baseUrl}/cdi`, params);
        this.lista =  request.buscaEngenheiros.data
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.lista = false;
    },

    async gerarExcel(id) {
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
      const authStore = useAuthStore();
      const token = authStore.token;

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow"
      };
        const response = await fetch(`${baseUrl}/cdi-export/${id}`, requestOptions)  
        const filePath = await response.text();
        this.filePath = filePath;   
        return this.filePath;
      } catch (erro) {
        this.erro = erro;
        console.error('Erro ao gerar Excel:', erro);
      } finally {
        this.chamadasPendentes.emFoco = false;
      }
    },
    
    async enviarOpiniaoEmail(id, params = {} ){
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        const resposta = await this.requestS.post(`${baseUrl}/cdi-send/${id}`, params);
        const alertStore = useAlertStore();
        alertStore.success('Arquivo colocado para fila de envio');
      } catch (erros) {
        this.erros = erros;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async salvarItem(params = {}, id = null) {
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        const request = await this.requestS.post(`${baseUrl}/cdi`, params);
        this.chamadasPendentes.emFoco = false;
        return request;
      } catch (erro) {
        this.erro = erro;
        this.chamadasPendentes.emFoco = false;
        return false;
      }
    },
  },
});
