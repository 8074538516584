export default {
  "Acre": {
    sigla: 'AC',
    nome: 'Acre',
  },
  "Alagoas": {
    sigla: 'AL',
    nome: 'Alagoas',
  },
  "Amapá": {
    sigla: 'AP',
    nome: 'Amapá',
  },
  "Amazonas": {
    sigla: 'AM',
    nome: 'Amazonas',
  },
  "Bahia": {
    sigla: 'BA',
    nome: 'Bahia',
  },
  "Ceará": {
    sigla: 'CE',
    nome: 'Ceará',
  },
  "Distrito Federal": {
    sigla: 'DF',
    nome: 'Distrito Federal',
  },
  "Espírito Santo": {
    sigla: 'ES',
    nome: 'Espírito Santo',
  },
  "Goiás": {
    sigla: 'GO',
    nome: 'Goiás',
  },
  "Maranhão": {
    sigla: 'MA',
    nome: 'Maranhão',
  },
  "Mato Grosso": {
    sigla: 'MT',
    nome: 'Mato Grosso',
  },
  "Mato Grosso do Sul": {
    sigla: 'MS',
    nome: 'Mato Grosso do Sul',
  },
  "Minas Gerais": {
    sigla: 'MG',
    nome: 'Minas Gerais',
  },
  "Pará": {
    sigla: 'PA',
    nome: 'Pará',
  },
  "Paraíba": {
    sigla: 'PB',
    nome: 'Paraíba',
  },
  "Paraná": {
    sigla: 'PR',
    nome: 'Paraná',
  },
  "Pernambuco": {
    sigla: 'PE',
    nome: 'Pernambuco',
  },
  "Piauí": {
    sigla: 'PI',
    nome: 'Piauí',
  },
  "Rio de Janeiro": {
    sigla: 'RJ',
    nome: 'Rio de Janeiro',
  },
  "Rio Grande do Norte": {
    sigla: 'RN',
    nome: 'Rio Grande do Norte',
  },
  "Rio Grande do Sul": {
    sigla: 'RS',
    nome: 'Rio Grande do Sul',
  },
  "Rondônia": {
    sigla: 'RO',
    nome: 'Rondônia',
  },
  "Roraima": {
    sigla: 'RR',
    nome: 'Roraima',
  },
  "Santa Catarina": {
    sigla: 'SC',
    nome: 'Santa Catarina',
  },
  "São Paulo": {
    sigla: 'SP',
    nome: 'São Paulo',
  },
  "Sergipe": {
    sigla: 'SE',
    nome: 'Sergipe',
  },
  "Tocantins": {
    sigla: 'TO',
    nome: 'Tocantins',
  },
};
