import { defineStore } from 'pinia';
import { useAuthStore } from '@/store/auth.store';
// eslint-disable-next-line no-undef
const baseUrl = process.env.VUE_APP_API_URL;

export const useImoveisRealizadosStore = defineStore('imoveisRealizados', {
  state: () => ({
    lista: [],
    loading: false,
    error: null
  }),
  actions: {
    async getImoveisRealizados(params = {}) {
      this.loading = true;
      this.error = null;

      const authStore = useAuthStore();
      const token = authStore.token;

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      try {
        const url = `${baseUrl}/realized-properties-by-distance?type=${params.tipo_imovel}&lat=${params.location.lat}&lng=${params.location.lng}`;
        const response = await fetch(url, requestOptions);
        if (!response.ok) throw new Error('Falha na requisição');
        const data = await response.json();
        this.lista = data.realizedProperties;
      } catch (error) {
        this.error = error;
        console.error('Erro no fetch:', error);
      } finally {
        this.loading = false;
      }
    }
  }
});
