export default {
  Venda: {
    nome: 'Venda',
    valor: 'Venda',
  },
  Aluguel: {
    nome: 'Aluguel',
    valor: 'Aluguel',
  },
};
