<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
import { cadastro as schema } from '@/consts/formSchema';
import { useAuthStore } from '@/store/auth.store';
import { vMaska } from "maska/vue";
import { ErrorMessage, Field, Form } from 'vee-validate';

const authStore = useAuthStore();

async function onSubmit(values) {
  await authStore.cadastro(values);
}

</script>

<template>
  <div class="avalionLogin">
    <div class="avalionFormLogin">
      <img 
        src="@/assets/imagens/avalionLogo.jpg"
        alt="Logo avalion"
        class="avalionLogo"
      >
      <Form
        v-slot="{ errors, isSubmitting }"
        :validation-schema="schema"
        @submit="onSubmit"
      >
        <div class="mb-2">
          <LabelFromYup 
            name="name" 
            :schema="schema"
          />
          <Field
            name="name"
            placeholder="Seu nome"
            type="text"
            class="inputtext"
            :class="{ 'error': errors.name }"
          />
          <ErrorMessage
            class="error-msg"
            name="name"
          />
        </div>

        <div class="form-group">
          <div class="mb-2">
            <LabelFromYup 
              name="email" 
              :schema="schema"
            />
            <Field 
              name="email"
              placeholder="seu_email@provedor.com" 
              type="text" 
              class="inputtext"
              :class="{ 'error': errors.email }"
            />
            <ErrorMessage 
              class="error-msg" 
              name="email"
            />
          </div>

          <div class="mb-2">
            <LabelFromYup 
              name="telefone_celular" 
              :schema="schema"
            />
            <Field 
              v-maska
              name="telefone_celular"
              type="text"
              placeholder="(00) 00000-0000"
              class="inputtext light mb-2"
              maxlength="15"
              data-maska="(##) #####-####"
              :class="{ 'error': errors.telefone_celular }"
            />
            <ErrorMessage
              class="error-msg"
              name="telefone_celular"
            />
          </div>
        </div>

        <div class="d-flex w-100 gap-3">
          <div class="mb-2 w-25">
            <LabelFromYup name="cep" :schema="schema"/>
            <Field
              v-maska
              name="cep"
              type="text"
              placeholder="CEP"
              class="inputtext light mb-2"
              maxlength="9"
              data-maska="#####-###"
              :class="{ 'error': errors.cep }"
            />
            <ErrorMessage
              class="error-msg"
              name="cep"
            />
          </div>

          <div class="mb-2 w-75">
            <LabelFromYup
              name="endereco" 
              :schema="schema"
            />
            <Field 
              name="endereco" 
              type="text" 
              placeholder="Rua" 
              class="inputtext light mb-2"
              :class="{ 'error': errors.endereco }"
            />
            <ErrorMessage 
              class="error-msg"
              name="endereco"
            />
          </div>
        </div>

        <div class="form-group">
          <div class="mb-2 w-100">
            <LabelFromYup 
              name="numero" 
              :schema="schema"
            />
            <Field 
              name="numero" 
              type="text" 
              placeholder="Número" 
              class="inputtext"
              :class="{ 'error': errors.numero }"
            />
            <ErrorMessage 
              class="error-msg" 
              name="numero"
            />
          </div>

          <div class="mb-2">
            <LabelFromYup 
              name="bairro" 
              :schema="schema"
            />
            <Field 
              name="bairro" 
              type="text" 
              placeholder="Bairro" 
              class="inputtext"
              :class="{ 'error': errors.bairro }"
            />
            <ErrorMessage 
              class="error-msg"
              name="bairro"
            />
          </div>
        </div>

        <div class="form-group">
          <div class="mb-2">
            <LabelFromYup 
              name="cidade" 
              :schema="schema"
            />
            <Field 
              name="cidade" 
              type="text" 
              placeholder="cidade" 
              class="inputtext"
              :class="{ 'error': errors.cidade }"
            />
            <ErrorMessage 
              class="error-msg" 
              name="cidade"
            />
          </div>

          <div class="mb-2">
            <LabelFromYup 
              name="estado" 
              :schema="schema"
            />
            <Field 
              name="estado" 
              type="text" 
              placeholder="Estado" 
              class="inputtext light mb-2"
              :class="{ 'error': errors.estado }"
            />
            <ErrorMessage 
              class="error-msg"
              name="estado"
            />
          </div>
        </div>
        
        <div class="mb-2">
          <LabelFromYup 
            name="complemento" 
            :schema="schema"
          />
          <Field 
            name="complemento"
            type="text" 
            placeholder="Complemento" 
            class="inputtext light mb-2"
            :class="{ 'error': errors.complemento }"
          />
          <ErrorMessage 
            class="error-msg" 
            name="complemento"
          />
        </div>

        <div class="form-group">
          <div class="mb-2">
            <LabelFromYup 
              name="password" 
              :schema="schema"
            />
            <Field
              name="password" 
              placeholder="*******" 
              type="password" 
              class="inputtext"
              :class="{ 'error': errors.password }"  
            />
            <ErrorMessage 
              class="error-msg" 
              name="password"
            />
          </div>

          <div class="mb-2">
            <LabelFromYup 
              name="password_confirmation" 
              :schema="schema"
            />
            <Field
              name="password_confirmation" 
              placeholder="*******" 
              type="password" 
              class="inputtext"
              :class="{ 'error': errors.password_confirmation }"  
            />
            <ErrorMessage 
              class="error-msg" 
              name="password_confirmation"
            />
          </div>
        </div>

        <div class="mb-2">
          <Field 
            name="termos" 
            type="checkbox" 
            class="pe-2 light mb-2" 
            :value="true"
            :class="{ 'error': errors.termos }"
          />
          <LabelFromYup 
            name="termos" 
            :schema="schema" 
            class="ps-2"
          />
          <a href="">Termos e Condições de Uso</a>
          <ErrorMessage 
            class="error-msg" 
            name="termos"
          />
        </div>

        <div class="d-flex flex-column align-items-center">
          <button 
            class="btnAmarelo" 
            :disabled="isSubmitting"
          >
            Enviar Link
          </button>
          <font-awesome-icon
            v-show="isSubmitting" 
            icon="spinner" 
            class="spinner mt-3"
          />
        </div>
      </Form>
    </div>
  </div>
</template>

<style scoped>
.avalionFormLogin {
  max-width: 700px;
}

.avalionLogin{
  height: initial;
}

.avalionLogo {
  max-width: 340px;
  display: block;
  margin: auto;
}
</style>