<template>
  <div class="card-branco">
    <router-link
      to="/amostras"
      class="btnVoltar"
    >
      <font-awesome-icon
        icon="circle-left"
      />
    </router-link>
    <h1 class="mb-3">
      {{ route?.meta?.título }}
    </h1>
    <form
      @submit.prevent="onSubmitAmostras"
    >
      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-4 w-100">
          <LabelFromYup 
            name="titulo" 
            :schema="schema"
            :required="true"
          />
          <Field
            name="titulo" 
            type="text" 
            placeholder="Título - Ex: Apartamento no Centro de Curitiba" 
            class="inputtext"
            :class="{ 'error': errors.titulo }"
          />
          <ErrorMessage 
            class="error-msg"
            name="titulo"
          />
        </div>

        <div class="mb-4 w-100">
          <LabelFromYup 
            name="anuncioUrl" 
            :schema="schema"
            :required="true"
          />
          <Field
            name="anuncioUrl" 
            type="text" 
            placeholder="https://www.imobiliaria.com.br/venda/casas/..." 
            class="inputtext"
            :class="{ 'error': errors.anuncioUrl }"
          />
          <ErrorMessage 
            class="error-msg"
            name="anuncioUrl"
          />
        </div>

        <div class="mb-4 w-100">
          <LabelFromYup 
            name="anunciante" 
            :schema="schema"
            :required="true"
          />
          <Field
            name="anunciante" 
            type="text" 
            placeholder="Anunciante" 
            class="inputtext"
            :class="{ 'error': errors.anunciante }"
          />
          <ErrorMessage 
            class="error-msg"
            name="anunciante"
          />
        </div>
      </div>

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-100">
          <LabelFromYup
            name="tipo"
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="tipo"
            name="tipo"
            as="select"
            class="inputtext"
            :class="{ 'error': errors.tipo }"
          >
            <option value="">
              Selecionar
            </option>
            <option
              v-for="item in Object.values(tipoImovel)"
              :key="item.valor"
              :value="item.valor"
            >
              {{ item.nome }}
            </option>
          </Field>
          <ErrorMessage
            name="tipo"
            class="error-msg"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup
            name="rural_urbano"
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="rural_urbano"
            name="rural_urbano"
            as="select"
            class="inputtext"
            :class="{ 'error': errors.rural_urbano }"
          >
            <option value="">
              Selecionar
            </option>
            <option
              v-for="item in Object.values(urbanoRural)"
              :key="item.valor"
              :value="item.valor"
            >
              {{ item.nome }}
            </option>
          </Field>
          <ErrorMessage
            name="rural_urbano"
            class="error-msg"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup
            name="tipo_negocio"
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="tipo_negocio"
            name="tipo_negocio"
            as="select"
            class="inputtext"
            :class="{ 'error': errors.tipo_negocio }"
          >
            <option value="">
              Selecionar
            </option>
            <option
              v-for="item in Object.values(tipoNegocio)"
              :key="item.valor"
              :value="item.valor"
            >
              {{ item.valor }}
            </option>
          </Field>
          <ErrorMessage
            name="tipo_negocio"
            class="error-msg"
          />
        </div>
      </div>

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-2 d-flex align-items-center">
          <div>
            <LabelFromYup 
              name="cep"
              :schema="schema"
              :required="true"
            />
            <Field
              v-model="cep"
              v-maska
              name="cep"
              type="text"
              placeholder="CEP"
              class="inputtext"
              maxlength="9"
              data-maska="#####-###"
              :class="{ 'error': errors.cep }"
              @focusout="getCep"
            />
            <ErrorMessage
              class="error-msg"
              name="cep"
            />
          </div>
          <span v-if="chamadasPendentes.emFoco">
            <font-awesome-icon
              icon="spinner" 
              class="spinner mt-3"
            />
          </span>
        </div>

        <div class="mb-2 w-75">
          <LabelFromYup
            name="logradouro" 
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="lista.logradouro"
            name="logradouro" 
            type="text" 
            placeholder="Rua" 
            class="inputtext"
            :class="{ 'error': errors.logradouro }"
          />
          <ErrorMessage 
            class="error-msg"
            name="logradouro"
          />
        </div>

        <div class="mb-3 w-25">
          <LabelFromYup 
            name="numero" 
            :schema="schema"
            :required="true"
          />
          <Field 
            name="numero" 
            type="text" 
            placeholder="Número" 
            class="inputtext"
            :class="{ 'error': errors.numero }"
          />
          <ErrorMessage 
            class="error-msg" 
            name="numero"
          />
        </div>

        <div class="mb-3 w-25">
          <LabelFromYup 
            name="latitude" 
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="location.latitude"
            name="latitude" 
            type="text" 
            placeholder="latitude" 
            class="inputtext"
            :class="{ 'error': errors.latitude }"
          />
          <ErrorMessage 
            class="error-msg"
            name="latitude"
          />
        </div>

        <div class="mb-3 w-25">
          <LabelFromYup 
            name="longitude" 
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="location.longitude"
            name="longitude" 
            type="text" 
            placeholder="longitude" 
            class="inputtext"
            :class="{ 'error': errors.longitude }"
          />
          <ErrorMessage 
            class="error-msg"
            name="longitude"
          />
        </div>
      </div>

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-75">
          <LabelFromYup 
            name="bairro" 
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="lista.bairro"
            name="bairro" 
            type="text" 
            placeholder="Bairro" 
            class="inputtext"
            :class="{ 'error': errors.bairro }"
          />
          <ErrorMessage 
            class="error-msg"
            name="bairro"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup 
            name="estado" 
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="lista.uf"
            name="estado" 
            type="text" 
            placeholder="Estado" 
            class="inputtext"
            :class="{ 'error': errors.estado }"
          />
          <ErrorMessage 
            class="error-msg"
            name="estado"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup 
            name="cidade"
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="lista.localidade"
            name="cidade" 
            type="text" 
            placeholder="cidade" 
            class="inputtext"
            :class="{ 'error': errors.cidade }"
          />
          <ErrorMessage 
            class="error-msg" 
            name="cidade"
          />
        </div>
      </div>

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-100">
          <LabelFromYup 
            name="preco" 
            :schema="schema"
            :required="true"
          />
          <FloatMaskInput 
            name="preco" 
            type="text" 
            placeholder="Valor Total"
            :class="{ 'error': errors.preco }"
            class="inputtext mb-2"
            :value="values.preco"
            converter-para="string"
          />
          <ErrorMessage 
            class="error-msg"
            name="preco"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup 
            name="area_total" 
            :schema="schema"
            :required="true"
          />
          <FloatMaskInput 
            name="area_total" 
            type="text" 
            placeholder="Àrea Total" 
            class="inputtext mb-2"
            :class="{ 'error': errors.area_total }"
            :value="values.area_total"
            converter-para="string"
          />
          <ErrorMessage 
            class="error-msg"
            name="area_total"
          />
        </div>
        
        <div class="mb-3 w-100">
          <LabelFromYup 
            name="area_util" 
            :schema="schema"
          />
          <FloatMaskInput 
            name="area_util" 
            type="text"
            :value="values.area_util"
            placeholder="Área Útil" 
            class="inputtext mb-2"
            converter-para="string"
          />
          <ErrorMessage 
            class="error-msg"
            name="area_util"
          />
        </div>

        <div class="mb-2 w-100">
          <LabelFromYup
            name="preco_metro_util" 
            :schema="schema"
          />
          <FloatMaskInput 
            name="preco_metro_util" 
            type="text" 
            placeholder="Valor m² área construída" 
            :class="{ 'error': errors.preco_metro_util }"
            class="inputtext"
            :value="values.preco_metro_util"
            converter-para="string"
          />
          <ErrorMessage 
            class="error-msg"
            name="preco_metro_util"
          />
        </div>
      </div>

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-2 w-100">
          <LabelFromYup
            name="preco_metro" 
            :schema="schema"
          />
          <FloatMaskInput 
            name="preco_metro" 
            type="text" 
            placeholder="Valor m² área total" 
            :class="{ 'error': errors.preco_metro }"
            class="inputtext"
            :value="values.preco_metro"
            converter-para="string"
          />
          <ErrorMessage 
            class="error-msg"
            name="preco_metro"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup 
            name="condominio" 
            :schema="schema"
          />
          <FloatMaskInput
            name="condominio" 
            type="text" 
            placeholder="Valor do condomínio"
            :value="values.condominio"
            class="inputtext"
            converter-para="string"
          />
          <ErrorMessage 
            class="error-msg" 
            name="condominio"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup 
            name="iptu" 
            :schema="schema"
          />
          <FloatMaskInput
            name="iptu" 
            type="text" 
            placeholder="Valor do IPTU Por Ano"
            :value="values.iptu"
            class="inputtext"
            converter-para="string"
          />
          <ErrorMessage 
            class="error-msg" 
            name="iptu"
          />
        </div>
      </div>

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-100">
          <LabelFromYup
            :schema="schema"
            name="vagas"
            class="label"
          />
          <Field
            name="vagas"
            type="number"
            min="0"
            class="inputtext"
          />
          <ErrorMessage
            name="vagas"
            class="error-msg"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup
            :schema="schema"
            name="banheiros"
            class="label"
          />
          <Field
            name="banheiros"
            type="number"
            min="0"
            class="inputtext"
          />
          <ErrorMessage
            name="banheiros"
            class="error-msg"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup
            :schema="schema"
            name="quartos"
            class="label"
          />
          <Field
            name="quartos"
            type="number"
            min="0"
            class="inputtext"
          />
          <ErrorMessage
            name="quartos"
            class="error-msg mb1"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup
            :schema="schema"
            name="suites"
            class="label"
          />
          <Field
            name="suites"
            type="number"
            min="0"
            class="inputtext"
          />
          <ErrorMessage
            name="suites"
            class="error-msg"
          />
        </div>
      </div>

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-100">
          <LabelFromYup 
            name="ano_construcao" 
            :schema="schema"
          />
          <Field 
            name="ano_construcao" 
            type="text" 
            placeholder="Ano da construção " 
            class="inputtext"
            :class="{ 'error': errors.ano_construcao }"
          />
          <ErrorMessage 
            class="error-msg"
            name="ano_construcao"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup 
            name="andares" 
            :schema="schema"
          />
          <Field 
            name="andares" 
            type="text" 
            placeholder="Andar do Imóvel" 
            class="inputtext"
            :class="{ 'error': errors.andares }"
          />
          <ErrorMessage 
            class="error-msg"
            name="andares"
          />
        </div>  
      </div>
      
      <div>
        <label>Características imóvel<span class="required">*</span></label>
        <QuillEditor
          id="descricao"
          v-model:content="descricao"
          content-type="html"
          theme="snow"
          class="mb-3"
          name="descricao"
        />   
      </div>

      <div class="mb-2 w-100">
        <label for="fotos">Mapa ou Foto do Bairro</label>
        <input
          id="fotos"
          name="fotos"
          type="file" 
          class="inputtext mb-2"
          multiple
          @change="onFileChange"
        >
        <small>Arquivos válidos: JPG, PNG e GIF</small>
      </div>

      <div 
        class="mt-3"
        v-if="fotos.length"
      >
        <strong
          style="color: var(--roxo)"
        >
          Fotos escolhidas
        </strong>
        <div class="d-flex flex-wrap gap-3 mt-2"> 
          <div 
            v-for="(foto, index) in fotos" 
            :key="index" 
            class="position-relative"
          >
            <img 
              :src="foto" 
              alt="Foto do imóvel" 
              class="img-thumbnail"
            >
            <button 
              type="button" 
              class="btnRoxo position-absolute top-0 end-0 px-3 py-2" 
              @click="removeImage(index)"
            >
              X
            </button>
          </div>
        </div>
      </div>

      <div v-if="emFoco?.descricao">
        <strong
          style="color: var(--roxo)"
        >
          Descrição
        </strong>
        <p 
          v-html=emFoco?.descricao
        />
      </div>

      <div 
        v-if="emFoco?.fotos"
        class="mt-3"
      >
        <strong
          style="color: var(--roxo)"
        >
          Fotos do imóvel
        </strong>
        <div class="d-flex flex-wrap gap-3 mt-2"> 
          <div 
            v-for="(foto, index) in emFoco?.fotos" 
            :key="index" 
            class="position-relative"
          >
            <img 
              :src="foto" 
              alt="Foto do imóvel" 
              class="img-thumbnail"
            >
          </div>
        </div>
      </div>

      <div class="d-flex flex-column align-items-center mt-4">
        <button 
          class="btnRoxo" 
          :disabled="isSubmitting"
        >
          Salvar
        </button>
        <font-awesome-icon
          v-show="isSubmitting" 
          icon="spinner" 
          class="spinner mt-3"
        />
      </div>
    </Form>
  </div>
</template>

<script setup>
import FloatMaskInput from '@/components/FloatMaskInput.vue';
import { amostras as schema } from '@/consts/formSchema';
import tipoImovel from '@/consts/tipoImovel';
import tipoNegocio from '@/consts/tipoNegocio';
import urbanoRural from '@/consts/urbanoRural';
import { useAlertStore } from '@/store/alert.store';
import { useAmostrasStore } from '@/store/amostras.store';
import { useCepStore } from '@/store/buscaCep.store';
import { vMaska } from "maska/vue";
import { storeToRefs } from 'pinia';
import { ErrorMessage, Field, useForm } from 'vee-validate';
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const alertStore = useAlertStore();

const cepGet = useCepStore()
const { lista, chamadasPendentes  } = storeToRefs(cepGet);

const amostrasStore = useAmostrasStore()
const { emFoco,  } = storeToRefs(amostrasStore);

const router = useRouter();
const route = useRoute();
const props = defineProps({
  amostraId: {
    type: Number,
    default: 0,
  },
});

const fotos = ref('')
const descricao = ref('');
const location = ref({
  latitude: '',
  longitude: ''
})

function onDescriptionInput(content) {
  descricao.value = content;
}

async function getCep(cep) {
  let ceptetste = cep.target.value.replace(/\D/g, '')
  try {
    await cepGet.getCep(ceptetste);
    location.value.latitude = cepGet.location.latitude;
    location.value.longitude = cepGet.location.longitude;
  } catch (error) {
    alertStore.error(error);
  }
}

async function toBase64(files) {
  return Promise.all([...files].map(file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }));
}

async function onFileChange(e) {
  const files = e.target.files;
  if (files.length) {
    try {
      fotos.value = await toBase64(files);
    } catch (error) {
      alertStore.error("Erro ao converter as imagens para base64");
    }
  }
}

function removeImage(index) {
  fotos.value.splice(index, 1);
}

const {
  errors, resetForm, handleSubmit, values
} = useForm({
  initialValues: emFoco.value,
  validationSchema: schema,
});

const onSubmitAmostras = handleSubmit(async (values) => {
  try {
    const msg = props.amostraId
      ? 'Dados salvos com sucesso!'
      : 'Item adicionado com sucesso!';

    const payload = {
      ...values,
      descricao: descricao.value || emFoco.value.descricao,
      fotos: fotos.value || emFoco.value.fotos,
      location: {
        "type": "Point",
        "coordinates": [
          location.value.longitude,
          location.value.latitude
        ]
      },
    };

     const resposta = props.amostraId
       ? await amostrasStore.salvarItem(payload, props.amostraId)
       : await amostrasStore.salvarItem(payload);  
       
      if (resposta) {
        alertStore.success(msg);
        amostrasStore.$reset();
        amostrasStore.buscarTudo()
        router.push({ name: "amostras" });
      }

  } catch (error) {
    alertStore.error(error);
  }
})

async function iniciar(){
  if (props.amostraId) {
  await amostrasStore.buscarItem(props.amostraId);
  }
}
iniciar()

watch(emFoco, (novoValor) => {
  resetForm({ values: novoValor });
});
</script>

<style scoped>
.ativo svg {
  color: var(--roxo);
  background-color: initial;
  margin-left: initial;
}

.img-thumbnail {
  padding: .25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 8px;
  max-width: 356px;
  object-fit: cover;
  height: 300px;
}
</style>