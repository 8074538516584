<template>
  <div class="card-branco">
    <router-link
      to="/orcamentos"
      class="btnVoltar"
    >
      <font-awesome-icon
        icon="circle-left"
      />
    </router-link>
    <div class="d-flex gap-3 align-items-center">
      <h1>{{ route?.meta?.título }}</h1>
    </div>
    <div class="mt-3" v-if="props.orcamentoId">
      <div class="notes py-3 px-3">
        <p 
          v-html=emFoco?.notes
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useOrcamentoStore } from '@/store/orcamento.store';
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';

const router = useRouter();
const route = useRoute();
const props = defineProps({
  orcamentoId: {
    type: Number,
    default: 0,
  },
});

const orcamentoStore = useOrcamentoStore()
const { emFoco } = storeToRefs(orcamentoStore)

if (props.orcamentoId) {
  orcamentoStore.buscarItem(props.orcamentoId);
}
</script>

<style scoped>
.ativo svg {
  background: initial; 
  color: var(--roxo); 
}

.notes{
  background-color: var(--cinzaClaro);
  border-radius: 7px;
}
</style>