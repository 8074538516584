export default {
  CemMetros: {
    nome: '100 Metros',
    valor: 100,
  },
  DuzentosMetros: {
    nome: '200 Metros',
    valor: 200,
  },
  TrezentosMetros: {
    nome: '300 Metros',
    valor: 300,
  },
  QuatrocentosMetros: {
    nome: '400 Metros',
    valor: 400,
  },
  QuinhentosMetros: {
    nome: '500 Metros',
    valor: 500,
  },
  SeiscentosMetros: {
    nome: '600 Metros',
    valor: 600,
  },
  SetecentosMetros: {
    nome: '700 Metros',
    valor: 700,
  },
  OitocentosMetros: {
    nome: '800 Metros',
    valor: 800,
  },
  NovecentosMetros: {
    nome: '900 Metros',
    valor: 900,
  },
  MilMetros: {
    nome: '1000 Metros',
    valor: 1000,
  },
  MilQuinhentosMetros: {
    nome: '1500 Metros',
    valor: 1500,
  },
  DoisMilMetros: {
    nome: '2000 Metros',
    valor: 2000,
  },
  DoisMilQuinhentosMetros: {
    nome: '2500 Metros',
    valor: 2500,
  },
  TresMilMetros: {
    nome: '3000 Metros',
    valor: 3000,
  },
  TresMilQuinhentosMetros: {
    nome: '3500 Metros',
    valor: 3500,
  },
  QuatroMilMetros: {
    nome: '4000 Metros',
    valor: 4000,
  },
  QuatroMilQuinhentosMetros: {
    nome: '4500 Metros',
    valor: 4500,
  },
  CincoMilMetros: {
    nome: '5000 Metros',
    valor: 5000,
  },
  CincoMilQuinhentosMetros: {
    nome: '5500 Metros',
    valor: 5500,
  },
  SeisMilMetros: {
    nome: '6000 Metros',
    valor: 6000,
  },
  SeisMilQuinhentosMetros: {
    nome: '6500 Metros',
    valor: 6500,
  },
  SeteMilMetros: {
    nome: '7000 Metros',
    valor: 7000,
  },
};