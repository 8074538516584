<template>
  <div class="card-branco">
    <div class="d-flex justify-content-between align-items-center pb-4">
      <h1>Capitação de Dados Imobiliários</h1>
      <div class="d-flex justify-content-between align-items-center gap-2">
        <router-link
          :to="{ name: 'Criar Capitação' }"
          class="btnRoxo"
        >
          Nova capitação de Dados Imobiliários
        </router-link>  
      </div>
    </div>
    <div class="py-3 px-3 bordaAmarela mb-3">
      <p>
        Tem Dúvidas de como fazer uma Pesquisa Imobiliária para Engenheiro? Veja o tutorial no nosso canal do Youtube
        <a
          target="_blank"
          href="https://www.youtube.com/watch?v=UDH1OQV5JbQ">
          aqui
        </a>.
      </p>
    </div>
    <strong
      style="color: var(--roxo)"
    >
      Filtrar por:
    </strong>
    <form
      class="mb-3 d-flex gap-3 flex-wrap align-items-end justify-content-between" 
      @submit.prevent="atualizarUrl"
    >
      <div class="d-flex gap-3 align-items-center">
        <div class="inputtextMenor">
          <label>Id</label>
          <input
            id="id"
            v-model.trim="idBusca"
            placeholder="ID"
            class="inputtext"
            name="id"
            type="text"
          >
        </div>

        <div class="mw-100">
          <label>Endereço</label>
          <input
            id="endereco"
            v-model.trim="endereco"
            placeholder="Endereço"
            class="inputtext"
            name="endereco"
            type="text"
          >
        </div>

        <div>
          <label>Bairro</label>
          <input
            id="bairro"
            v-model.trim="bairro"
            placeholder="Bairro"
            class="inputtext"
            name="bairro"
            type="text"
          >
        </div>

        <div>
          <label>Tipo de negócio</label>
          <select
            id="tipo_negocio"
            v-model.trim="tipo_negocio"
            class="inputtext"
            name="tipo_negocio"
          >
            <option 
              value=""
            />
            <option
              v-for="item in Object.values(tipoNegocio)"
              :key="item.valor"
              :value="item.valor"
            >
              {{ item.nome }}
            </option>
          </select>
        </div>

        <div>
          <label>Tipo de Imóvel</label>
          <select
            id="tipo_imovel"
            v-model.trim="tipo_imovel"
            class="inputtext"
            name="tipo_imovel"
          >
            <option 
              value=""
            />
            <option
              v-for="item in Object.values(tipoImovel)"
              :key="item.valor"
              :value="item.valor"
            >
              {{ item.valor }}
            </option>
          </select>
        </div>
      </div>
      <div class="d-flex gap-3">
        <button @click="limparFiltro" type="button" class="btnLabel">
          <font-awesome-icon
            icon="trash"
          />
          Limpar
        </button>
        <button class="btnAmarelo">
          Pesquisar
        </button>
      </div>
    </form>
    <div class="tabela">
      <table class="w-100">
        <col>
        <col>
        <col>
        <col>
        <col>
        <thead>
          <tr>
            <th>Tipo de imóvel</th>
            <th>Negócio</th>
            <th>Endereço</th>
            <th>Área privativa</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="item in lista"
            :key="item.id"
          >
            <td class="tipo">{{ item.tipo_imovel }}</td>
            <td>{{ item.tipo_negocio }}</td> 
            <td class="endereco">{{ item.endereco }}</td>
            <td> {{ item.created_at ? dateToField(item.created_at) : '-' }}</td>
            <div class="d-flex">
              <td class="tdBtnAcao">
                <button
                  class="icon"
                  @click="abrirModalEmailOpiniao(item.id)"
                >
                  <font-awesome-icon icon="envelope" />
                </button>
              </td>
              <td class="tdBtnAcao">
                <button
                  class="icon"
                  @click="gerarExcel(item.id)"
                >
                  <font-awesome-icon icon="file-pdf" />
                </button>
              </td>
            </div> 
          </tr>
          <tr v-if="chamadasPendentes.lista">
            <td colspan="3">
              <font-awesome-icon
                icon="spinner" 
                class="spinner mt-3"
              />
              Carregando
            </td>
          </tr>
          <tr v-else-if="erro">
            <td colspan="3">
              Erro: {{ erro }}
            </td>
          </tr>
          <tr v-else-if="!lista.length">
            <td colspan="3">
              Nenhum resultado encontrado.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="mostrarModal"
      class="modal fade show"
      style="display: block; background-color: rgba(0,0,0,0.5);" 
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header justify-content-between">
            <h3> Enviar Capitação de Dados por e-mail</h3>
            <button 
              type="button" 
              class="Modalclose"
              @click="fecharModalEmailOpiniao"
              aria-label="Close"
            >
              <font-awesome-icon icon="circle-xmark" />
            </button>
          </div>
          <div class="modal-body">
            <Form
              v-slot="{ errors, isSubmitting }"
              :validation-schema="schemaOpiniaoEmail"
              @submit="enviarOpiniao"
            >
              <div class="mb-4">
                <LabelFromYup 
                  name="email"
                  :schema="schemaOpiniaoEmail"
                  :required="true"
                />
                <Field 
                  name="email" 
                  placeholder="seu_email@provedor.com" 
                  type="text" 
                  class="inputtext"
                  :class="{ 'error': errors.email }"
                />
                <ErrorMessage 
                  class="error-msg" 
                  name="email"
                />
              </div>
              <div class="modal-footer py-0">
                <button 
                  class="btnBranco"
                  data-bs-dismiss="modal"
                >
                  Fechar
                </button>
                <button 
                  class="btnRoxo" 
                  :disabled="isSubmitting"
                >
                  Enviar
                </button>
                <font-awesome-icon 
                  v-show="isSubmitting" 
                  icon="spinner" 
                  class="spinner mt-3"
                />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  layout as schemaLayout,
  email as schemaOpiniaoEmail
} from '@/consts/formSchema';
import layoutPdf from '@/consts/layoutPdf';
import tipoImovel from '@/consts/tipoImovel';
import tipoNegocio from '@/consts/tipoNegocio';
import dateToField from '@/helpers/dateToFiel';
import { useAlertStore } from '@/store/alert.store';
import { useCapitacaoDadosStore } from '@/store/capitacaoDadosImobiliarios.store';
import { storeToRefs } from 'pinia';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const alertStore = useAlertStore();
const route = useRoute();
const router = useRouter();

const capitacaoDadosStore = useCapitacaoDadosStore()
const { lista, chamadasPendentes, erro } = storeToRefs(capitacaoDadosStore);

capitacaoDadosStore.buscarTudo()

const mostrarModal = ref(false)
const mostrarModalPdf = ref(false)
const idSelecionado = ref(null);
const teste = ref(null);
const fileXml = ref(null)
const idBusca = ref(route.query.id)
const endereco = ref(route.query.endereco)
const bairro = ref(route.query.bairro)
const tipo_negocio = ref(route.query.tipo_negocio
? Object.keys(tipoNegocio)
  .find((x) => x.toLowerCase() === route.query.tipo_negocio.toLocaleLowerCase())
: undefined);

const tipo_imovel = ref(route.query.tipo_imovel
? Object.keys(tipoImovel)
  .find((x) => x.toLowerCase() === route.query.tipo_imovel.toLocaleLowerCase())
: undefined);


const consulta = {};
function atualizarUrl() {

  if (idBusca.value) {
    consulta.id = idBusca.value;
  }

  if (endereco.value) {
    consulta.endereco = endereco.value;
  }

  if (bairro.value) {
    consulta.neighborhood = bairro.value;
  }

  if (tipo_negocio.value) {
    consulta.business = tipo_negocio.value;
  }

  if (tipo_imovel.value) {
    consulta.type = tipo_imovel.value;
  }

  router.push({
    query: consulta,
  });
}

watch([
  () => route.query.id,
  () => route.query.endereco,
  () => route.query.neighborhood,
  () => route.query.type,
  () => route.query.business,
], () => {
  capitacaoDadosStore.$reset();
  capitacaoDadosStore.buscarTudo(consulta);
}, { immediate: true });

function limparFiltro(){
  idBusca.value = '';
  endereco.value = '';
  bairro.value = '';
  tipo_negocio.value = '';
  tipo_imovel.value = '';

  router.push({
    query: {}
  });

  capitacaoDadosStore.$reset();
  capitacaoDadosStore.buscarTudo({});
}

async function enviarXml(){
  if (!fileXml.value) {
    alertStore.error('Por favor, selecione um arquivo.');
    return;
  }
  try{
    const formData = new FormData();
    formData.append('file', fileXml.value);
    await capitacaoDadosStore.enviarXml(formData)
    alertStore.success('Arquivo enviado com sucesso!');
  } catch(erro){
    alertStore.error('Erro ao enviar o arquivo: ' + erro.message); 
  }
}

function abrirModalEmailOpiniao(id) {
  idSelecionado.value = id;
  mostrarModal.value = true;
  document.body.style.overflow = 'hidden';
}

function fecharModalEmailOpiniao() {
  mostrarModal.value = false;
  document.body.style.overflow = 'auto';
}

async function enviarOpiniao(email) {
  try {
    if (idSelecionado.value) {
      await capitacaoDadosStore.enviarOpiniaoEmail(idSelecionado.value, email);
      fecharModalEmailOpiniao();
    }
  } catch (erro) {
    alertStore.error(erro);
  }
}

async function gerarExcel(id) {
  try {
    if (id) {
      const resposta = await capitacaoDadosStore.gerarExcel(id)
      const pdfLink = resposta;
      window.open(pdfLink, '_blank');
      fecharModalEmailOpiniao();
    }
  } catch (erro) {
    alertStore.error(erro);
  }
}

</script>

<style scoped>
.endereco, 
.tipo{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 230px;
}

.endereco{
  max-width: 230px;
}

.tipo{
  max-width: 150px;
}
</style>