// stores/itemStore.js
import { defineStore } from 'pinia';

export const useItemStore = defineStore('item', {
  state: () => ({
    selectedItem: null,
  }),
  actions: {
    setSelectedItem(item) {
      this.selectedItem = item;
    },
    clearSelectedItem() {
      this.selectedItem = null;
    },
  },
});