/* eslint-disable no-undef */
import { createRouter, createWebHistory } from 'vue-router';

import Home  from '@/views/Home.vue';
import Dashboard from '@/views/dashboard/Index.vue'
import IndexLogin from '@/views/login/Login.index.vue'
import { Login, EsqueciSenha, Cadastro } from '@/views/login/index'
import MeuPerfil from '@/views/perfil/MeuPerfil.vue'

import BairroLista from '@/views/bairro/BairroLista.vue'
import BairroCriarEditar from '@/views/bairro/BairroCriarEditar.vue'

import Usuarios from '@/views/usuarios/UsuariosLista.vue'
import UsuarioCriarEditar from '@/views/usuarios/UsuarioCriarEditar.vue'

import PtamsLista from '@/views/PTAMs/PtamsLista.vue';
import PtamsEditarCriar from '@/views/PTAMs/ptamsEditarCriar.vue';
import PtamsRascunhoLista from '@/views/PTAMs/PtamsRascunhoLista.vue';

import OpiniaoMercadoLista from '@/views/opiniaoMercado/OpiniaoMercadoLista.vue';
import OpiniaoMercadoCriar from '@/views/opiniaoMercado/OpiniaoMercadoCriar.vue';
import OpiniaoMercadoEditar from '@/views/opiniaoMercado/OpiniãoMercadoEditar.vue';
import OpiniaoMercadoAmostra from '@/views/opiniaoMercado/OpiniaoMercadoAmostra.vue';

import OrcamentoLista from '@/views/orcamento/OrcamentoLista.vue';
import OrcamentoEditarCriar from '@/views/orcamento/OrcamentoCriarEditar.vue';
import OrcamentoComentarios from '@/views/orcamento/OrcamentoComentarios.vue';

import ImoveisVendidosLista from '@/views/imoveisVendidos/ImoveisVendidosLista.vue';
import ImoveisVendidosCriarEditar from '@/views/imoveisVendidos/ImoveisVendidosCriarEditar.vue';

import AnaliseMercadoLista from '@/views/analiseMercado/AnaliseMercadoLista.vue';
import AnaliseMercadoCriarEditar from '@/views/analiseMercado/AnaliseMercadoCriarEditar.vue';

import PesquisaInteligenteLista from '@/views/pesquisaInteligente/PesquisaInteligenteLista.vue';
import PesquisaInteligenteCriarEditar from '@/views/pesquisaInteligente/PesquisaInteligenteCriarEditar.vue';
import PesquisaInteligenteEditar from '@/views/pesquisaInteligente/PesquisaInteligenteEditar.vue';

import AmostrasLista from '@/views/amostras/AmostrasLista.vue';
import AmostrasCriarEditar from '@/views/amostras/AmostrasCriarEditar.vue';

import CapitacaoDadosImobiliariosLista from '@/views/capitacaoDadosImobiliarios/CapitacaoDadosImobiliariosLista.vue';
import CapitacaoDadosImobiliariosCriarEditar from '@/views/capitacaoDadosImobiliarios/CapitacaoDadosImobiliariosCriarEditar.vue';
import UsuarioEditar from '@/views/usuarios/UsuarioEditar.vue';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkActiveClass: 'ativo',
    routes: [
      {
        path: '/',
        component: Home,
        children: [
          {
            path: '',
            name: 'Dashboard',
            component: Dashboard,
          },
          {
            path: '/perfil',
            name: 'Perfil',
            component: MeuPerfil,
          }
        ],
      },
      {
        path: '/usuarios',
        component: Home,
        children: [
          {
            path: '',
            name: 'Usuarios',
            component: Usuarios,
          },
          {
            path: 'novoUsuario',
            name: 'Usuario Criar',
            component: UsuarioCriarEditar,
            meta: {
              título: 'Criar Usuário',
            },
          },
          {
            path: ':usuarioId',
            name: 'Usuario Editar',
            component: UsuarioEditar,
            meta: {
              título: 'Editar Usuário',
            },
            props: ({ params }) => ({
              ...params,
              ...{ usuarioId: Number.parseInt(params.usuarioId, 10) || undefined },
            }),
          },
        ]
      },
      {
        path: '/ptams',
        component: Home,
        children: [
          {
            path: '',
            name: 'Lista ptams',
            component: PtamsLista,
          },
          {
            path: 'novoPtams',
            name: 'Novo Ptams',
            component: PtamsEditarCriar,
            meta: {
              título: 'Novo PTAMs',
            },
          },
          {
            path: 'rascunho',
            name: 'Ptams rascunho',
            component: PtamsRascunhoLista,
            meta: {
              título: 'Rascunhos PTAMs',
            },
          },
          {
            path: ':imovelVendidoId',
            name: 'ImoveisVendidosEditar',
            component: ImoveisVendidosCriarEditar,
            meta: {
              título: 'Editar PTAMs',
            },
            props: ({ params }) => ({
              ...params,
              ...{ imovelVendidoId: Number.parseInt(params.imovelVendidoId, 10) || undefined },
            }),
          },
        ]
      },
      {
        path: '/analiseMercado',
        component: Home,
        children: [
          {
            path: '',
            name: 'Análise de mercado',
            component: AnaliseMercadoLista,
          },
          {
            path: 'novaAnaliseMercado',
            name: 'Criar Análise de mercado',
            component: AnaliseMercadoCriarEditar,
            meta: {
              título: 'Criar Análise de mercado',
            },
          },
          {
            path: ':analiseMercadoId',
            name: 'Editar Análise de mercado',
            component: AnaliseMercadoCriarEditar,
            meta: {
              título: 'Editar Análise de mercado',
            },
            props: ({ params }) => ({
              ...params,
              ...{ analiseMercadoId: Number.parseInt(params.analiseMercadoId, 10) || undefined },
            }),
          },
        ]
      },
      {
        path: '/opnioesMercado',
        component: Home,
        children: [
          {
            path: '',
            name: 'Opiniões de mercado',
            component: OpiniaoMercadoLista,
          },
          {
            path: 'novaOpiniaoMercado',
            name: 'OpiniaoMercadoCriar',
            component: OpiniaoMercadoCriar,
          },
          {
            path: ':opiniaoMercadoId',
            name: 'OpiniaoMercadoEditar',
            component: OpiniaoMercadoEditar,
            props: ({ params }) => ({
              ...params,
              ...{ opiniaoMercadoId: Number.parseInt(params.opiniaoMercadoId, 10) || undefined },
            }),
          },
          {
            path: 'novaOpiniaoMercado/imovel/:amostraId',
            name: 'OpiniaoMercadoAmostra',
            component: OpiniaoMercadoAmostra,
            props: true
          },
        ]
      },
      {
        path: '/pesquisaInteligente',
        component: Home,
        children: [
          {
            path: '',
            name: 'Pesquisa Inteligente',
            component: PesquisaInteligenteLista,
          },
          {
            path: 'novaPesquisa',
            name: 'Criar Pesquisa Inteligente',
            component: PesquisaInteligenteCriarEditar,
            meta: {
              título: 'Criar Pesquisa Inteligente',
            },
          },
          {
            path: ':pesquisaInteligenteId',
            name: 'Editar Pesquisa Inteligente',
            component: PesquisaInteligenteEditar,
            meta: {
              título: 'Editar Pesquisa Inteligente',
            },
            props: ({ params }) => ({
              ...params,
              ...{ pesquisaInteligenteId: Number.parseInt(params.pesquisaInteligenteId, 10) || undefined },
            }),
          },
        ]
      },
      {
        path: '/capitacaoDadosImobiliarios',
        component: Home,
        children: [
          {
            path: '',
            name: 'Capitação Dados Imobiliários',
            component: CapitacaoDadosImobiliariosLista,
          },
          {
            path: 'novaCapitacao',
            name: 'Criar Capitação',
            component: CapitacaoDadosImobiliariosCriarEditar,
            meta: {
              título: 'Criar capitação de Dados Imobiliários',
            },
          },
        ]
      },
      {
        path: '/orcamentos',
        component: Home,
        children: [
          {
            path: '',
            name: 'orcamentos',
            component: OrcamentoLista,
          },
          {
            path: 'novoOrcamento',
            name: 'Orçamento criar',
            component: OrcamentoEditarCriar,
            meta: {
              título: 'Novo orçamento',
            },
          },
          {
            path: ':orcamentoId',
            name: 'OrcamentoEditar',
            component: OrcamentoEditarCriar,
            meta: {
              título: 'Editar orçamento',
            },
            props: ({ params }) => ({
              ...params,
              ...{ orcamentoId: Number.parseInt(params.orcamentoId, 10) || undefined },
            }),
          },
          {
            path: ':orcamentoId',
            name: 'Comentários do orçamento',
            component: OrcamentoComentarios,
            meta: {
              título: 'Comentários',
            },
            props: ({ params }) => ({
               ...params,
               ...{ orcamentoId: Number.parseInt(params.orcamentoId, 10) || undefined },
             }),
          },
          
        ]
      },
      {
        path: '/imoveisVendidos',
        component: Home,
        children: [
          {
            path: '',
            name: 'Imoveis vendidos',
            component: ImoveisVendidosLista,
          },
          {
            path: 'novoImovel',
            name: 'Adicionar Imóvel',
            component: ImoveisVendidosCriarEditar,
            meta: {
              título: 'Novo imóvel',
            },
          },
          {
            path: ':imovelVendidoId',
            name: 'ImoveisVendidosEditar',
            component: ImoveisVendidosCriarEditar,
            meta: {
              título: 'Editar imóvel',
            },
            props: ({ params }) => ({
              ...params,
              ...{ imovelVendidoId: Number.parseInt(params.imovelVendidoId, 10) || undefined },
            }),
          },
        ]
      },
      {
        path: '/bairros',
        component: Home,
        children: [
          {
            path: '',
            name: 'bairros',
            component: BairroLista,
          },
          {
            path: 'novoBairro',
            name: 'BairroCriarEditar',
            component: BairroCriarEditar,
            meta: {
              título: 'Criar bairro',
            },
          },
          {
            path: ':bairroId',
            name: 'BairroEditar',
            component: BairroCriarEditar,
            meta: {
              título: 'Editar bairro',
            },
            props: ({ params }) => ({
              ...params,
              ...{ bairroId: Number.parseInt(params.bairroId, 10) || undefined },
            }),
          },
        ]
      },
      {
        path: '/amostras',
        component: Home,
        children: [
          {
            path: '',
            name: 'amostras',
            component: AmostrasLista,
          },
          {
            path: 'novaAmostra',
            name: 'Amostras criar',
            component: AmostrasCriarEditar,
            meta: {
              título: 'Nova amostra',
            },
          },
          {
            path: ':amostraId',
            name: 'AmostrasEditar',
            component: AmostrasCriarEditar,
            meta: {
              título: 'Editar amostra',
            },
            props: ({ params }) => ({
              ...params,
              ...{ amostraId: params.amostraId || undefined },
            }),
          },
        ]
      },
      {
        path: '/Login',
        component: IndexLogin,
        children: [
          {
            path: '',
            name: 'Login',
            component: Login,
          },
          {
            path: '/esqueci-minha-senha',
            name: 'Esqueci minha senha',
            component: EsqueciSenha,
          },
          {
            path: '/cadastro',
            name: 'Cadastre-se',
            component: Cadastro,
          },
        ]
      },
    ],
  });

  router.beforeEach(async(r) => {
    const { useAuthStore } = require('@/store/auth.store');
    
    const publicPages = ['/login', '/esqueci-minha-senha', '/cadastro'];
    const authRequired = !publicPages.includes(r.path);
    const authStore = useAuthStore();
  
    if (authRequired && !authStore.token) {
      authStore.returnUrl = r.fullPath;
      return '/login';
    }
  });
  
  export default router;