<template>
  <div class="card-branco">
    <router-link
      :to="{ name: 'Lista ptams' }"
      class="btnVoltar"
    >
      <font-awesome-icon
        icon="circle-left"
      />
    </router-link>
    <div class="d-flex justify-content-between align-items-center pb-4">
      <h1>{{ route?.meta?.título }}</h1>
    </div>
    <form
      class="mb-3 d-flex gap-3 flex-wrap align-items-end" 
      @submit.prevent="atualizarUrl"
    >
      <div class="d-flex gap-3 align-items-center">
        <div>
          <label>Filtrar por:</label>
          <input
            id="id"
            v-model.trim="idBusca"
            placeholder="ID"
            class="inputtext "
            name="id"
            type="text"
          >
        </div>
      </div>
      <div class="d-flex gap-3">
        <button @click="limparFiltro" type="button" class="btnLabel">
          <font-awesome-icon
            icon="trash"
          />
          Limpar
        </button>
        <button class="btnAmarelo">
          Pesquisar
        </button>
      </div>
    </form>
    <div class="tabela">
      <table class="w-100">
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <thead>
          <tr>
            <th>ID</th>
            <th>Endereço</th>
            <th>Tipo de imóvel</th>
            <th>Negócio</th>
            <th>Data</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="tdBtnAcao">
              <router-link
                to="/login"
                class="icon"
              >
                <font-awesome-icon
                  icon="pen-to-square"
                />
              </router-link>
            </td>
            <td class="tdBtnAcao">
              <button
                class="icon"
                arial-label="excluir"
                title="excluir"
                @click="excluirRascunho(item.id)"
              >
                <font-awesome-icon
                  icon="trash"
                />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { useOpiniaoMercadoStore } from '@/store/opiniaoMercado.store';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const opiniaoMercadoStore = useOpiniaoMercadoStore()
const { lista, linhas, chamadasPendentes, erro} = storeToRefs(opiniaoMercadoStore);

const route = useRoute();
const router = useRouter();

const idBusca = ref(route.query.id)

const consulta = {};
function atualizarUrl() {

  if (idBusca.value) {
    consulta.id = idBusca.value;
  }

  router.push({
    query: consulta,
  });
}

watch([
  () => route.query.id,
], () => {
  opiniaoMercadoStore.$reset();
  opiniaoMercadoStore.buscarTudo(consulta);
}, { immediate: true });

function limparFiltro(){
  idBusca.value = ''; 
  router.push({
    query: {}
  });
  opiniaoMercadoStore.$reset();
  opiniaoMercadoStore.buscarTudo({});
}

</script>

<style scoped>
.ativo svg {
  color: var(--roxo);
  background-color: initial;
  margin-left: initial;
}
</style>