<template>
  <div>
    <router-view />
  </div>
</template>

<script setup>

</script>

<style scoped>

</style>