export default {
  ptam: {
    nome: 'PTAMs',
    valor: 'ptam',
  },
  analise: {
    nome: 'Análise de Mercado',
    valor: 'analise',
  },
  opiniao: {
    nome: 'Opiniões de Mercado',
    valor: 'opiniao',
  },
};
