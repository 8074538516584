import { defineStore } from 'pinia';
import { useAlertStore } from '@/store/alert.store';

// eslint-disable-next-line no-undef
const baseUrl = process.env.VUE_APP_API_URL;

export const useAnaliseMercadoStore = defineStore('AnaliseMercado', {
  state: () => ({
    lista: [],
    listaCalculo: [],
    item: null,
    emFoco: null,
    filePath: "",
    analiseId: null,
    chamadasPendentes: {
      lista: false,
      listaCalculo: false,
      item: false,
      emFoco: false,
      filePath: false,
      analiseId: false,
    },
    erro: null,
  }),
  actions: {
    async buscarTudo(params = {}) {
      this.chamadasPendentes.lista = true;
      this.erro = null;

      try {
        const request  = await this.requestS.get(`${baseUrl}/market-analysis`, params);
        this.lista = request.marketAnalysis
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.lista = false;
    },
    
    async buscarItem(id) {
      this.chamadasPendentes.emFoco = true;
      this.chamadasPendentes.item = true;
      this.chamadasPendentes.listaCalculo = true;
      this.erro = null;

      try {
        const request = await this.requestS.get(`${baseUrl}/market-analysis/${id}`);
        this.emFoco = request.marketAnalysis
        this.chamadasPendentes.item = false;
        this.chamadasPendentes.listaCalculo = false;
        this.analiseId = request.marketAnalysis.id;
        this.listaCalculo = request.marketAnalysis.samples
        this.item = request.marketAnalysis
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async excluirItem(id) {
      this.chamadasPendentes.lista = true;
      this.erro = null;

      try {
        await this.requestS.delete(`${baseUrl}/market-analysis/${id}`);
        this.chamadasPendentes.lista = false;
        return true;
      } catch (erro) {
        this.erro = erro;
        this.chamadasPendentes.lista = false;
        return false;
      }
    },

    async excluirAmostraRecalcular(id) {
      this.chamadasPendentes.lista = true;
      this.erro = null;

      try {
        await this.requestS.delete(`${baseUrl}/market-analysis-delete-sample/${id}`);
        this.chamadasPendentes.lista = false;
        return true;
      } catch (erro) {
        this.erro = erro;
        this.chamadasPendentes.lista = false;
        return false;
      }
    },

    async gerarPdf(id){
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        const resposta = await this.requestS.post(`${baseUrl}/market-analysis-print/${id}`);
        this.filePath = resposta.filePath
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.emFoco = false;
    },

    
    async anexarFoto(id, params){
      console.log(id, params)
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        await this.requestS.upload(`${baseUrl}/market-analysis-files/${id}`, params);
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async atualizarFoto(id, params){
      console.log(id, params)
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        await this.requestS.put(`${baseUrl}/market-update-file/${id}`, params);
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async enviarAnaliseEmail(id, params = {} ){
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        const request = await this.requestS.post(`${baseUrl}/market-analysis-send/${id}`, params);
        const alertStore = useAlertStore();
        alertStore.success('Arquivo colocado para fila de envio');
        return request
      } catch (erros) {
        this.erros = erros;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async salvarItem(params = {}) {
      this.chamadasPendentes.emFoco = true;
      this.chamadasPendentes.item = true;
      this.chamadasPendentes.listaCalculo = true;
      this.erro = null;
      try {
        const request =  await this.requestS.post(`${baseUrl}/market-analysis`, params)
        this.chamadasPendentes.emFoco = false;
        this.chamadasPendentes.item = false;
        this.chamadasPendentes.listaCalculo = false;
        this.analiseId = request.marketAnalysis.id;
        this.listaCalculo = request.marketAnalysis.samples
        this.item = request.marketAnalysis
        return request
      } catch (erro) {
        this.erro = erro;
        this.chamadasPendentes.emFoco = false;
        return false;
      }
    },
  },
});
