export default {
  "financiamento": {
    nome: 'Financiamento',
    valor: 'financiamento',
  },
  "a vista": {
    nome: 'A vista',
    valor: 'a vista',
  },
};
