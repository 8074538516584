<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <footer class="footerAvalion">
    <p>Avalion © Todos os direitos reservados | 2024 |
      <a href="">Termos de Uso </a>
    </p>
  </footer>
</template>

<style>
.footerAvalion{
    background-color: var(--cinzaClaro);
    padding: 20px 0;
    width: 100%;
    bottom: 0;
}

.footerAvalion p,
.footerAvalion a{
    text-align: center;
    color: var(--roxo);
    font-weight: 500;
}
</style>
