export default {
  "aprovado": {
    nome: 'Aprovado',
    valor: 'aprovado',
  },
  "recusado": {
    nome: 'recusado',
    valor: 'recusado',
  },
  "sem resposta": {
    nome: 'sem resposta',
    valor: 'sem resposta',
  },
};
