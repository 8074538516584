export default {
  "urbano": {
    nome: 'Urbano',
    valor: 'urbano',
  },
  "rural": {
    nome: 'Rural',
    valor: 'rural',
  },
};
