export default {
  "1ª Região - Rio de Janeiro": {
    nome: '1ª Região - Rio de Janeiro',
    valor: '1ª Região - Rio de Janeiro',
  },
  "2ª Região - São Paulo": {
    nome: '2ª Região - São Paulo',
    valor: '2ª Região - São Paulo',
  },
  "3ª Região - Rio Grande do Sul": {
    nome: '3ª Região - Rio Grande do Sul',
    valor: '3ª Região - Rio Grande do Sul',
  },
  "4ª Região - Minas Gerais": {
    nome: '4ª Região - Minas Gerais',
    valor: '4ª Região - Minas Gerais',
  },
  "5ª Região - Goiás": {
    nome: '5ª Região - Goiás',
    valor: '5ª Região - Goiás',
  },
  "6ª Região - Paraná": {
    nome: '6ª Região - Paraná',
    valor: '6ª Região - Paraná',
  },
  "7ª Região - Pernambuco": {
    nome: '7ª Região - Pernambuco',
    valor: '7ª Região - Pernambuco',
  },
  "8ª Regiao - Distrito Federal": {
    nome: '8ª Regiao - Distrito Federal',
    valor: '8ª Regiao - Distrito Federal',
  },
  "9ª Região - Bahia": {
    nome: '9ª Região - Bahia',
    valor: '9ª Região - Bahia',
  },
  "11ª Regiao - Santa Catarina": {
    nome: '11ª Regiao - Santa Catarina',
    valor: '11ª Regiao - Santa Catarina',
  },
  "12ª Região - Pará / Amapá": {
    nome: '12ª Região - Pará / Amapá',
    valor: '12ª Região - Pará / Amapá',
  },
  "13ª Região - Espírito Santo": {
    nome: '13ª Região - Espírito Santo',
    valor: '13ª Região - Espírito Santo',
  },
  "14ª Região - Mato Grosso do Sul": {
    nome: '14ª Região - Mato Grosso do Sul',
    valor: '14ª Região - Mato Grosso do Sul',
  },
  "15ª Região - Ceará": {
    nome: '15ª Região - Ceará',
    valor: '15ª Região - Ceará',
  },
  "16ª Região - Sergipe": {
    nome: '16ª Região - Sergipe',
    valor: '16ª Região - Sergipe',
  },
  "17ª Região - Rio Grande do Norte": {
    nome: '17ª Região - Rio Grande do Norte',
    valor: '17ª Região - Rio Grande do Norte',
  },
  "18ª Região - Amazonas e Roraima": {
    nome: '18ª Região - Amazonas e Roraima',
    valor: '18ª Região - Amazonas e Roraima',
  },
  "19ª Região - Mato Grosso": {
    nome: '19ª Região - Mato Grosso',
    valor: '19ª Região - Mato Grosso',
  },
  "20ª Região - Maranhão": {
    nome: '20ª Região - Maranhão',
    valor: '20ª Região - Maranhão',
  },
  "21ª Região - Paraíba": {
    nome: '21ª Região - Paraíba',
    valor: '21ª Região - Paraíba',
  },
  "22ª Região - Alagoas": {
    nome: '22ª Região - Alagoas',
    valor: '22ª Região - Alagoas',
  },
  "23ª Região - Piauí": {
    nome: '23ª Região - Piauí',
    valor: '23ª Região - Piauí',
  },
  "24ª Região - Rondônia": {
    nome: '24ª Região - Rondônia',
    valor: '24ª Região - Rondônia',
  },
  "25ª Região - Tocantins": {
    nome: '25ª Região - Tocantins',
    valor: '25ª Região - Tocantins',
  },
  "26ª Região - Acre": {
    nome: '26ª Região - Acre',
    valor: '26ª Região - Acre',
  },
};
