export default {
  // Apartamentos
  'Apartamento': {
    nome: 'Apartamento',
    valor: 'Apartamento',
  },
  'Flat': {
    nome: 'Flat',
    valor: 'Flat',
  },
  'Cobertura': {
    nome: 'Cobertura',
    valor: 'Cobertura',
  },
  'Kitinet': {
    nome: 'Kitinet',
    valor: 'Kitinet',
  },
  'Loft': {
    nome: 'Loft',
    valor: 'Loft',
  },
  'Studio': {
    nome: 'Studio',
    valor: 'Studio',
  },

  //Residencias
  'Casa de Condomínio': {
    nome: 'Casa de Condomínio',
    valor: 'Casa de Condomínio',
  },
  'Casa': {
    nome: 'Casa',
    valor: 'Casa',
  },
  'Sobrado': {
    nome: 'Sobrado',
    valor: 'Sobrado',
  },

  //Rural
  'Chácara': {
    nome: 'Chácara',
    valor: 'Chácara',
  },

  //Lotes e Terrenos
  'Lote/Terreno': {
    nome: 'Lote/Terreno',
    valor: 'Lote/Terreno',
  },

  //Comercial
  'Ponto comercial/Loja/Box': {
    nome: 'Ponto comercial/Loja/Box',
    valor: 'Ponto comercial/Loja/Box',
  },
  'Prédio/Edificio inteiro': {
    nome: 'Prédio/Edificio inteiro',
    valor: 'Prédio/Edificio inteiro',
  },
  'Casa Comercial': {
    nome: 'Casa Comercial',
    valor: 'Casa Comercial',
  },
  'Sala/Conjunto': {
    nome: 'Sala/Conjunto',
    valor: 'Sala/Conjunto',
  },

  //Industrial
  'Galpão/Depósito/Armazém': {
    nome: 'Galpão/Depósito/Armazém',
    valor: 'Galpão/Depósito/Armazém',
  },

  //Garagem
  'Garagem': {
    nome: 'Garagem',
    valor: 'Garagem',
  },
};
