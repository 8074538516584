import responseDownload from '@/helpers/responseDownload';
import { useAlertStore } from '@/store/alert.store';
import { useAuthStore } from '@/store/auth.store';

export const requestS = {
  get: request('GET'),
  post: request('POST'),
  upload: request('POST', true),
  put: request('PUT'),
  patch: request('PATCH'),
  delete: request('DELETE'),
};

function request(method, isUpload = false) {
  return async (url, params) => {
    const requestOptions = {
      method,
      headers: userToken(url),
    };

    if (method === 'PUT') {
      requestOptions.headers['_method'] = 'POST';
    }

    switch (method) {
      case 'GET':
        if (params && Object.keys(params).length) {
          url += `?${new URLSearchParams(params).toString()}`;
        }
        break;

      default:
        if (params && !isUpload) {
          requestOptions.headers['Content-Type'] = 'application/json';
          requestOptions.body = JSON.stringify(params);
        } else if (params instanceof FormData) {
          requestOptions.body = params;
        } else if (params) {
          requestOptions.body = params;
        }
        break;
    }

    const response = await fetch(url, requestOptions);
    return handleResponse(response);
  };
}

function userToken(url) {
  const { token } = useAuthStore();
  const isLoggedIn = !!token;
  // eslint-disable-next-line no-undef
  const isApiUrl = url.startsWith(process.env.VUE_APP_API_URL);
  if (isLoggedIn && isApiUrl) {
    return { Authorization: `Bearer ${token}` };
  }
  return {};
}

function listErrors(r) {
  let o = '';
  if (typeof r === 'object') {
    r.forEach((rr) => {
      o += `${rr.includes('|') ? rr.split('| ')[1] : rr}\r\n`;
    });
    return o;
  }
  return r;
}

async function handleResponse(response) {
  const isJson = response.headers?.get('content-type')?.includes('application/json');
  const isZip = response.headers?.get('content-type')?.includes('application/zip');
  
  const data = isJson ? await response.json() : true;
  if (!response.ok) {
    if ([20].includes(response.status)) return;
    const alertStore = useAlertStore();
    const { user } = useAuthStore();
    let msgDefault;
    if ([401, 403].includes(response.status) && user) {
      msgDefault = 'Sem permissão para acessar.';
    }
    if ([502].includes(response.status) && user) {
      msgDefault = 'Erro de comunicação do servidor.';
    }
    const error = (data && data.message) ? listErrors(data.message) : msgDefault ?? response.status;
    alertStore.error(error);
    return Promise.reject(error);
  } 
  if (isJson) {
    responseDownload(response);
  }
  return data;
}
