export default {
  "clean": {
    nome: 'clean',
    valor: 'clean',
  },
  "default": {
    nome: 'default',
    valor: 'default',
  },
};
