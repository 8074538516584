import { defineStore } from 'pinia';
import { useAlertStore } from '@/store/alert.store';
import dataFormatada from '@/helpers/dataFormatada';
import { useRouter } from 'vue-router';

const router = useRouter();

// eslint-disable-next-line no-undef
const baseUrl = process.env.VUE_APP_API_URL;

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    user: JSON.parse(localStorage.getItem('user')),
    token: JSON.parse(localStorage.getItem('token')),
    returnUrl: null,
  }),
  actions: {
    async login(user) {
      try {
        const request = await this.requestS.post(`${baseUrl}/auth/login`, user);
        console.log(request)
        this.token = request.token;
        localStorage.setItem('token', JSON.stringify(request.token))
        await this.getDados();
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },
    async getDados() {
      try {
        const request = await this.requestS.get(`${baseUrl}/auth/me`);
        this.user = request 
        localStorage.setItem('user', JSON.stringify(request))
        return request;
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
        throw error;
      }
    },
    async cadastro(newUser) {
      try {
        const request = await fetch(`${baseUrl}/auth/register`, newUser);
        const alertStore = useAlertStore();
        alertStore.success('Conta criado com sucesso!');
        this.router.push('/login');
        if (!request.ok) {
          throw new Error('Login failed');
        }
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async atualizarPerfil(user) {
      try {
        const payload = {
          // _method: 'PUT',
          ...user,
        };

        const request = await this.requestS.put(`${baseUrl}/auth/profile/${this.user.id}`, payload);
        const alertStore = useAlertStore();
        alertStore.success('Perfil atualizado com sucesso!');

        await this.getDados()
        
        if (!request.ok) {
          throw new Error('Atualização falhou');
        }
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async esqueciSenha(email){
      try{
        const request = this.requestS.post(`${baseUrl}/auth/forgot-password`, email);
        const alertStore = useAlertStore();
        alertStore.success('Uma senha temporária foi enviada para o seu e-mail.');
        this.router.push('/login');
        if (!request.ok) {
          throw new Error('Recuperação de senha, falhou');
        }

      } catch (error){
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },
    async novaSenhaPerfil(password) {
      try {
        const request = await this.requestS.put(`${baseUrl}/auth/profile/password/${this.user.id}`, password);
        const alertStore = useAlertStore();
        alertStore.success('Senha salva com sucesso.');
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      this.$reset();
    },
  },

  getters:{
    itemParaEdição({ emFoco }) {
      return {
        ...emFoco,
      };
    },
  }
});
